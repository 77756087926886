<template>
  <div class="text-start wrapper">
    <header>
      <div class="container py-5">
        <h1 class="heading mb-4">
          <slot name="title"></slot>
        </h1>
        <div id="No" class="container-scroll">
          <div class="d-flex flex-nowrap align-items-center justify-items-center">
            <slot name="header"></slot>
          </div>
        </div>
      </div>
    </header>

    <div class="container px-3 pb-5">
      <div id="testing" class="container-scroll">
        <div class="course-list">
          <slot name="body"></slot>
        </div>
      </div>
    </div>

    <div class="container-fluid videos-bg p-3">
      <div class="container-scroll videos-wrapper">
        <div class="videos">
          <div class="row g-3">
            <div class="col-4">
              <video controls class="video">
                <source src="@/assets/videos/smk-cover-song-2.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="col-4">
              <video controls class="video">
                <source src="@/assets/videos/Shwe-Maw-Kun-End-Of-Summer.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="col-4">
              <video controls class="video">
                <source src="@/assets/videos/SMK-Secondary-coding.mp4" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer>
    <div class="container-fluid">
      <div class="row justify-content-center p-5">
        <div class="col-sm-4">
          <div class="bd_footer">
            <h1 class="text-start text-uppercase" style="font-size:25px;font-weight:900">Shwe Maw Kun</h1>
            <div class="text-start">
              <router-link class="nav-link" :to="{ name: 'AboutSchool' }">About Us</router-link>
              <router-link class="nav-link" :to="{ name: 'Fees' }">School Fees</router-link>
              <router-link class="nav-link" :to="{ name: 'Career' }">Career</router-link>
              <router-link class="nav-link" :to="{ name: 'NewsAndActivities' }">News And Activities</router-link>
            </div>
          </div>
        </div>
        <div class="col-sm-4 bd_footer">
          <i class="bi bi-geo-alt icon"></i>
          <div>
            <h1 class="text-uppercase text-start" style="font-size:25px;font-weight:900">{{ fontPath == 1 ?
              "Contact Us" : "ဆက်သွယ်ရန်ဖုန်းနံပတ်များ" }}</h1>
            <p class="mb-1 fw-normal text-start">{{ fontPath == 1 ? "09 252447066" : "၀၉ ၂၅၂၄၄၇၀၆၆" }}</p>
            <p class="mb-1 fw-normal text-start">{{ fontPath == 1 ? "09 428377766" : "၀၉ ၄၂၈၃၇၇၇၆၆" }}</p>

          </div>
        </div>
        <!-- here -->
        <div class="col-sm-4 footer-links bd_footer">
          <div class="d-flex justify-content-center social-links footer_cent">
            <div>
              <h1 class="text-uppercase text-start" style="font-size:25px;font-weight:900;">{{ fontPath == 1 ? "Address" :
                "ဆက်သွယ်ရန်လိပ်စာ" }}</h1>
              <p class="mb-1 fw-normal text-start">
                <template v-if="fontPath == 1">
                  No. 20/10,Shwe Chi Street, (Corner of Yae Htwet Oo Street),<br /> Yae Aye Kwin Quarter, Taunggyi, Shan State,
                  Myanmar
                </template>
                <template v-if="fontPath == 2">
                  <span>အမှတ်(၂၀/၁၀)၊ ရွှေခြည်လမ်း နှင့် ရေထွက်ဦး လမ်းထောင့် <br /> ရေအေးကွင်းရပ်၊ တောင်ကြီးမြို့။</span>
                </template>
              </p>
            </div>
          </div>
        </div>
        <!-- here -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  created() {
    this.$store.state.footer = false
  },
  computed: {
    fontPath() {
      return this.$store.state.font_type;
    }
  },
  beforeUnmount() {
    this.$store.state.footer = true
  }
}
</script>

<style lang="scss" scoped>
.heading {
  text-transform: uppercase;
  color: var(--cus-secondary);
  font-weight: var(--cus-fw-semibold);
}

.btn {
  background-color: var(--cus-white);
  min-width: 120px;
  text-transform: uppercase;
  color: var(--cus-primary)
}

.label-bar {
  background-color: var(--cus-grey);
  border-bottom: 1px solid #808080;

  .label {
    font-size: var(--cus-fs-large);
    color: var(--cus-secondary-bold);
    font-weight: var(--cus-fw-semibold);
  }
}

.course-list {
  width: 600px;
  background-color: var(--cus-white);

  @include breakpoint(medium) {
    width: auto;
  }
}

.video {
  background-color: white;
  width: 100%;
  height: 300px;
  object-fit: fill;
}

.videos {
  width: 900px;

  @include breakpoint(small) {
    width: 1140px;
  }

  @include breakpoint(large) {
    width: 100%;
  }
}

.videos-bg {
  background-color: var(--cus-grey);
}

.container-scroll {
  overflow-x: auto;
  padding: 10px 0px;
}

.videos-wrapper {
  @include breakpoint(xlarge) {
    max-width: 1140px;
    margin: 0 auto;
  }

  @include breakpoint(xxlarge) {
    max-width: 1320px;
    margin: 0 auto;
  }
}

.wrapper {
  background-color: var(--cus-primary);
}

footer {
  background-image: url('@/assets/img/background.png');
  background-position: center;
  background-size: cover;
}

.nav-link {
  color: white;

  &:hover {
    color: rgb(200, 196, 196)
  }
}
</style>