<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <template v-else>
    <div style="background-color:rgb(244, 234, 223)" class="p-3">
      <div
        class="modal fade"
        id="createmodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                User Register
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div>
                <div class="row d-flex justify-content-center">
                  <div class="col-sm-10 ms-3">
                    <h1 class="m-3">User Register</h1>
                    <div class="mt-5 ">
                      <div class="row ">
                        <div class="col-sm-6 ">
                          <div class="mb-3 row">
                            <label
                              for="username"
                              class="col-sm-2 col-form-label"
                              >Name</label
                            >
                            <div class="col-sm-8 ms-3">
                              <input
                                type="text"
                                class="form-control"
                                v-model="currentForm.username"
                                id="username"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="mb-3 row">
                            <label
                              for="password"
                              class="col-sm-2 col-form-label"
                              >Password</label
                            >
                            <div class="col-sm-8 ms-3">
                              <input
                                type="password"
                                class="form-control"
                                v-model="currentForm.password"
                                id="password"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6 ">
                          <div class="mb-3 row">
                            <label class="col-sm-2 col-form-label">Role</label>
                            <select
                              class="col-sm-8 ms-4"
                              aria-label="Default select example"
                              v-model="currentForm.role"
                              id="role"
                            >
                              <option
                                :value="index"
                                :key="index"
                                v-for="(role, index) in roleOptions"
                              >
                                {{ role }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="mb-3 row">
                            <label for="phone" class="col-sm-2 col-form-label "
                              >Phone Number</label
                            >
                            <div class="col-sm-8 ms-3">
                              <input
                                type="text"
                                class="form-control"
                                v-model="currentForm.phone"
                                id="phone"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-6">
                          <div class="mb-3 row">
                            <label for="address" class="col-sm-2 col-form-label"
                              >Address</label
                            >
                            <div class="col-sm-8 ms-3">
                              <textarea
                                name=""
                                class="form-control"
                                v-model="currentForm.address"
                                required
                                id="address"
                                cols="30"
                                rows="5"
                                style="resize: none;"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-destory"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                class="d-flex justify-align-center btn btn-primary"
                @click="storeRecord"
              >
                <span
                  id="createbtnspinner"
                  class="spinner-border"
                  role="status"
                  aria-hidden="true"
                  v-if="isSaveBtnLoading"
                ></span>
                <span id="createbtntext" v-if="! isSaveBtnLoading"
                  >Save Record</span
                >
              </button>

              <button
                id="createclose"
                style="visibility: hidden;"
                type="button"
                data-bs-dismiss="modal"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h1 class="fw-bold">User List</h1>
        <div class="d-flex justify-content-end mt-4">
          <button
            v-on:click="createRecord"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#createmodal"
          >
            Create User
          </button>
        </div>

        <div class="card mt-5">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 ms-1">
                <!-- <div class="row d-flex justify-content-end mt-5 ">
                  <div class="col-sm-5 ms-3">
                    <div class="mb-3 row">
                      <label for="search-key" class="col-sm-4 col-form-label"
                        >Search by</label
                      >
                      <div class="col-sm-6 ms-1">
                        <input
                          type="text"
                          class="form-control"
                          v-model="search"
                          @keyup="searchData()"
                          id="search-key"
                        />
                      </div>
                    </div>
                  </div>
                </div> -->

                <div class="row d-flex justify-content-center mt-5 ">
                  <div class="ms-1">
                    <div
                      class="container"
                      style="max-width:100%; max-height:1000px; overflow:scroll;"
                    >
                      <table class="table table-bordered table-hover">
                        <thead
                          style="position: sticky;top: 0;background-color: white;"
                        >
                          <tr>
                            <th>No</th>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Phone</th>
                            <th>Address</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <template v-if="isTableLoading">
                            <tr>
                              <td colspan="6">
                                <Loading />
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <template v-if="users.length > 0">
                              <tr v-for="(user, index) in users" :key="user.id">
                                <td>{{ index + 1 }}</td>
                                <td>{{ user.username ?? "-" }}</td>
                                <td>{{ roleOptions[user.role] ?? "-" }}</td>
                                <td>{{ user.phone ?? "-" }}</td>
                                <td>{{ user.address ?? "-" }}</td>
                                <td>
                                  <button
                                    v-on:click="editRecord(index)"
                                    class="btn btn-warning me-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#createmodal"
                                  >
                                    Edit
                                  </button>

                                  <button
                                    v-on:click="deleteRecord(user.id)"
                                    class="btn btn-danger"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            </template>
                            <tr v-else>
                              <td colspan="6">No user record exist!</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { DeviceUUID } from 'device-uuid';
import axios from 'axios';
import $ from 'jquery';
import Loading from '@/components/general/Loading.vue';

export default {
  components: {
    Loading
  },
  setup() {
    return {
      defaultForm: {
        id: null,
        username: '',
        role: 1,
        address: '',
        phone: ''
      },
      roleOptions: ["Super Admin", "Admin"],
      isSuperAdmin: localStorage.getItem("isSuperAdmin"),
    }
  },

  data() {
    return {

      link:"https://backend.smkedugroup.com",
      isLoading: true,
      isTableLoading: true,
      isSaveBtnLoading: false,
      users:[],
      userID: '',
      localtoken: '',
      deviceID: '',
      search: '',
      currentForm: this.defaultForm,

      isDisplay: 'isDisplay',
    };
  },

  methods: {
    searchData() {
      if(this.search == "") {
        this.show();
        return;
      }

      this.isTableLoading = true;

      let article = {
        search:this.search.toLowerCase()
      };

      axios.post(this.link + "/api/user", article)
        .then(response =>{
          this.users = response.data.users;
        })
        .catch(error => {
          this.users = [];
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    alreadyLogin() {
      let article = {
        userID:this.userID,
        localtoken:this.localtoken ,
        deviceID:this.deviceID,
      };

      axios.post(this.link+"/api/alreadyLogin", article)
        .then(response =>{
          if(response.data.state == "failed"){
            window.location.assign("/login")
          }
          else if(response.data.state == "success") {
            this.isSuperAdmin = response.data.isSuperAdmin;
            if (this.isSuperAdmin != "true") {
              window.location.assign("/");
            }
            localStorage.setItem("token",response.data.Token);
            localStorage.setItem("deviceID", response.data.deviceID);
            localStorage.setItem("userID", response.data.userid);
          }
        })
        .catch(error => {
          window.location.assign("/login");
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    show() {
      this.isTableLoading = true;

      axios.get(this.link+"/api/user")
        .then(response => {
          this.users = response.data.users;
        })
        .catch(error => {
          this.users = [];
          this.errorMessage = error.message;
          console.error("There was an error!", error);}
        )
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    editRecord(index) {
      this.currentForm = this.users[index];
    },

    createRecord() {
      this.currentForm = this.defaultForm;
    },

    storeRecord() {
      // add superadmin permission condition
      if (this.currentForm.username == "") {
        return document.getElementById("username").focus();
      }
      if (this.currentForm.password == "") {
        return document.getElementById("password").focus();
      }
      if (this.currentForm.role === "") {
        return document.getElementById("role").focus();
      }
      if (this.currentForm.phone == "") {
        return document.getElementById("phone").focus();
      }
      if (this.currentForm.address == "") {
        return document.getElementById("address").focus();
      }

      let payload = {
        ...this.currentForm,
        role: this.currentForm.role + '',
      };

      let url = this.link + "/api/user";

      this.isSaveBtnLoading = true;
      if (this.currentForm.id) {
        url += '/' + this.currentForm.id;

        axios.put(url, payload)
          .then(response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Error',
              confirmButtonText: 'OK',
            });
          })
          .finally(() => {
            this.show();
            this.isSaveBtnLoading = false;
            $('#createclose').click();
          });
      } else {
        axios.post(url, payload)
          .then(response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Success',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Error',
              confirmButtonText: 'OK',
            });
          })
          .finally(() => {
            this.show();
            this.isSaveBtnLoading = false;
            $('#createclose').click();
          });
      }
    },

    deleteRecord(id) {
      let result = confirm("Want to delete?");
      if (result) {
        axios.delete(this.link+"/api/user/" + id)
          .then(response => {
            console.log(response);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Deleted',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => this.show());
      }
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      this.deviceID = localStorage.getItem("deviceID") ?? new DeviceUUID().get();
      this.localtoken = localStorage.getItem("token") ?? "Null";
      this.userID = localStorage.getItem("userID") ?? "Null";

      this.alreadyLogin();

      this.show();
    } catch (error) {
      console.log("error mounting admin user page: ", error);
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped></style>
