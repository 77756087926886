<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <div v-else>
    <div class="container mt-5">
      <div
        class="d-flex justify-content-start align-items-center gap-3 flex-wrap mb-4"
      >
        <label for="search-key">
          Please enter your phone number or email:
        </label>
        <div class="">
          <input
            type="text"
            class="form-control"
            :value="search"
            @input="searchData"
            id="search-key"
          />
        </div>
      </div>

      <div
        class="w-full d-flex flex-column align-items-start gap-3"
        v-if="search != ''"
      >
        <h2>Appointment Tracking List</h2>

        <div
          v-if="isTableLoading"
          style="width: 100%"
        >
          <Loading />
        </div>
        <template v-else>
          <div
            v-if="appointmentList.length > 0"
            class="accordion"
            style="width: 100%"
            id="accordionExample"
          >
            <div
              v-for="appointment in appointmentList"
              :key="appointment.id"
              class="accordion-item"
            >
              <h2 class="accordion-header" :id="'heading' + appointment.id">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse' + appointment.id"
                  aria-expanded="false"
                  :aria-controls="'collapse' + appointment.id"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="plus-icon"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="minus-icon"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 12h-15"
                    />
                  </svg>
                  '{{ appointment.student_name }}' at
                  {{ appointment.booking_date }}
                  ({{ appointment.booking_slot?.start_time }} -
                  {{ appointment.booking_slot?.end_time }}) is '{{ statusOptions[appointment.status]
                  }}'
                </button>
              </h2>

              <div
                :id="'collapse' + appointment.id"
                class="accordion-collapse collapse"
                :aria-labelledby="'heading' + appointment.id"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div class="avice_color" style="color: white">
                    <div>
                      <h4>
                        Appointment {{ statusOptions[appointment.status] }}
                      </h4>
                      <div class="row gx-3 gy-3 p-2 text-start">
                        <div class="col-6">
                          Parent Name: {{ appointment.parent_name }}
                        </div>
                        <div class="col-6">
                          Student Name: {{ appointment.student_name }}
                        </div>

                        <div class="col-6">Email: {{ appointment.email }}</div>
                        <div class="col-6">Phone: {{ appointment.phone }}</div>

                        <div class="col-6">
                          Booking Date: {{ appointment.booking_date }}
                        </div>
                        <div class="col-6">
                          Booking Time:
                          {{ appointment.booking_slot?.start_time }} -
                          {{ appointment.booking_slot?.end_time }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            Oops... There's no appointment registered with {{ search }} at the
            moment. Please register now.
          </div>
        </template>
      </div>
      <div v-else class="text-start">No appointment yet.</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/general/Loading.vue';

export default {
  props: {
    selectedAppointmentId: {
      default: null,
    }
  },

  components: {
    Loading
  },

  data(){
    return{
      
      link: "https://backend.smkedugroup.com",
      isTableLoading: true,
      appointmentList: [],
      search: '',
      statusOptions: {
        0: 'Pending',
        1: 'Confirmed',
        2: 'Canceled'
      },
      currentForm: {
        id: '',
        parent_name: '',
        student_name: '',
        email: '',
        phone: '',
        courses: [],
        booking_date: '',
        booking_time: '',
      },
      timer: 500,
    }
  },

  methods: {
    searchData(event) {
      this.isTableLoading = true;
      this.search = event.target.value;
      this.filterRecords();
    },

    filterRecords() {
      if(this.search == "") {
        return;
      }
      let payload = {};
      payload.search = this.search.toLowerCase();

      clearTimeout(this.timer);

      let newTimer = 100;
      newTimer = setTimeout(() => {
        axios.get(this.link+"/api/appointments-search", {
          params: {...payload}
        })
          .then(response => {
            this.appointmentList = response.data;
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => this.isTableLoading = false);
      }, this.timer);

      this.timer = newTimer;
    },
  },
}
</script>

<style lang="scss" scoped>
.clear-fix {
  clear: both;
}

.plus-icon {
  flex-shrink: 0;
  display: none;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.minus-icon {
  flex-shrink: 0;
  display: none;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.accordion-button {
  text-transform: uppercase;
}

.accordion-button:not(.collapsed) .minus-icon {
  display: inline-block;
}

.accordion-button.collapsed .plus-icon {
  display: inline-block;
}

.accordion-button::after {
  display: none !important;
}

.accordion-body {
  padding: 0;
}

.avice_color {
  background: rgb(55, 0, 58);
  background: linear-gradient(355deg,
      rgba(55, 0, 58, 1) 0%,
      rgba(77, 0, 82, 1) 49%,
      rgba(113, 0, 120, 1) 100%);
}
</style>
