<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <template v-else>
    <div class="row">
      <div class="col-12">
        <div class="row gx-0" style="background-color:rgb(244, 234, 223)">
          <div class="col-lg-6 p-6">
            <AppointmentCalendar @eventClick="handleEventClick" :isTriggerReload="isTriggerReload" />
          </div>
          <div class="col-lg-6">
            <AppointmentList :selectedAppointmentId="selectedAppointmentId" @tableReload="handleTableReload" />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import AppointmentList from './AppointmentList.vue';
import Loading from '@/components/general/Loading.vue';
import AppointmentCalendar from './AppointmentCalendar.vue';

export default {
  components: {
    Loading,
    AppointmentList,
    AppointmentCalendar
  },

  data(){
    return{
      
      link: "https://backend.smkedugroup.com",
      isLoading: true,
      userID: '',
      localtoken: '',
      deviceID: '',
      selectedAppointmentId: null,
      isTriggerReload: null,
    }
  },

  methods: {
    handleEventClick(event) {
      this.selectedAppointmentId = event.appointment_id;
    },

    handleTableReload(event) {
      this.isTriggerReload = event.isTriggerReload;
    }
  },

  async mounted() {
    this.isLoading = true;

    try {
    } catch (error) {
      console.log("error mounting admin user page: ", error);
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped></style>
