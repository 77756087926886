<template>
  <template v-if="isLoading">
    <div>
      <Loading />
    </div>
  </template>
  <template v-else>
    <div class="container">
      <div class="d-flex justify-content-start mt-3">
        <button id="backButton" class="btn btn-primary btn-lg shadow" @click="goBack">
          <i class="bi bi-arrow-left-circle"></i> Go Back
        </button>
      </div>
      <div class="row my-5">
        <div class="col-12">
          <div class="d-flex justify-content-between mb-3">
            <h3 class="fw-bold">
              Course Edit
            </h3>
          </div>
          <div class="card p-3 pb-0">
            <form action="">
              <div class="row mb-3">
                <div class="col-6">
                  <label class="form-label d-block text-start">Class Type</label>
                  <select class="form-select" aria-label="Default select example" v-model="payload.class_type">
                    <option :value="type" :key="index" v-for="(type, index) in classTypes">{{ type }}</option>
                  </select>
                </div>
                <template v-if="payload.class_type === 'weekday classes'">
                  <div class="col-6">
                    <label class="form-label d-block text-start">Year Levels</label>
                    <select class="form-select" aria-label="Default select example" v-model="payload.year_level">
                      <option :value="level" :key="index" v-for="(level, index) in yearLevels['one']">{{ level }}</option>
                    </select>
                  </div>
                </template>
                <template v-if="payload.class_type === 'weekend classes & summer classes'">
                  <div class="col-6">
                    <label class="form-label d-block text-start">Year Levels</label>
                    <select class="form-select" aria-label="Default select example" v-model="payload.year_level">
                      <option :value="level" :key="index" v-for="(level, index) in yearLevels['two']">{{ level }}</option>
                    </select>
                  </div>
                </template>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <label class="form-label d-block text-start">From</label>
                  <select class="form-select" aria-label="Select From Month" v-model="payload.fromMonth">
                    <option :value="month" :key="month" v-for="(month, index) in months">{{ month }}</option>
                  </select>
                </div>
                <div class="col-6">
                  <label class="form-label d-block text-start">To</label>
                  <select class="form-select" aria-label="Select To Month" v-model="payload.toMonth">
                    <option :value="month" :key="month" v-for="(month, index) in months">{{ month }}</option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <label for="intake" class="form-label d-block text-start">Intake</label>
                  <input type="text" class="form-control" id="intake" v-model="payload.intake">
                </div>
                <div class="col-6">
                  <label for="order" class="form-label d-block text-start">Order</label>
                  <input type="text" class="form-control" id="order" v-model="payload.order">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6">
                  <div class="mb-3">
                    <h4>Curriculum</h4>
                  </div>
                  <div class="mb-3">
                    <div class="mb-3">
                      <label for="title" class="form-label d-block text-start w-100">New title name</label>
                      <input type="text" class="form-control" id="title" v-model="src.title">
                    </div>
                    <div class="d-flex justify-content-end">
                      <button type="button" class="btn btn-primary" @click="addCurriculum">Add new curriculum</button>
                    </div>
                  </div>
                  <template v-if="payload.curriculum.length > 0">
                    <h4 class="text-start mb-3">Curriculum List</h4>
                    <div v-for="(each, curriculumIndex) in payload.curriculum" :key="curriculumIndex"
                      class="border p-3 mb-3 rounded shadow-sm card">
                      <div class="mb-3 d-flex align-items-start justify-content-between">
                        <div class="flex flex-col w-100 me-3">
                          <div class="mb-3">
                            <label for="title" class="form-label d-block text-start">Title</label>
                            <input type="text" class="form-control me-3"
                              v-model="payload.curriculum[curriculumIndex].title">
                          </div>
                        </div>
                        <button type="button" class="btn btn-danger"
                          @click="removeCurriculum(curriculumIndex)">Remove</button>
                      </div>
                      <hr>
                      <template v-if="each.lists && each.lists.length > 0">
                        <div class="mb-3">
                          <label for="list" class="form-label d-block text-start">List</label>
                          <div class="text-start border p-3">
                            <div class="d-flex align-items-center justify-content-between mb-3"
                              v-for="(item, listItemIndex) in each.lists" :key="listItemIndex">
                              <input type="text" class="form-control me-3"
                                v-model="payload.curriculum[curriculumIndex].lists[listItemIndex]">
                              <button type="button" class="btn btn-danger"
                                @click="removeList(curriculumIndex, listItemIndex)">Remove</button>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div class="mb-3 d-flex align-items-end justify-content-between">
                        <div class="w-100 me-3">
                          <label for="list" class="form-label d-block text-start">New list name</label>
                          <textarea row="2" class="form-control" id="list" v-model="src.currentList"/>
                        </div>
                        <button type="button" class="btn btn-primary" @click="addList(curriculumIndex)">Add new
                          list</button>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="mt-5">
                      No Curriculum Yet!
                    </div>
                  </template>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-primary" :disabled="isSubmitting" @click="updateCourse">{{
                  isSubmitting
                  ? 'Updating...' : 'Update' }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { months } from "@/utils/index"
import courseService from "@/services/courseApi"
import { courseConstant } from "@/constants/index"
import Loading from "@/components/general/Loading.vue";

export default {
  components: {
    Loading
  },
  data() {
    return {
      link: "https://backend.smkedugroup.com",
      months: months,
      payload: {
        class_type: 'weekday classes',
        year_level: '',
        intake: '',
        from: '',
        to: '',
        order: 1,
        curriculum: [],
      },
      src: {
        currentList: ''
      },
      courses: [],
      classTypes: courseConstant.classTypes,
      yearLevels: courseConstant.yearLevels,
      isLoading: true,
      isSubmitting: false,
    }
  },
  props: ['id'],
  methods: {
    addCurriculum() {
      let data = {
        title: this.src.title ?? '',
        lists: [],
      }
      this.payload.curriculum.push(data)
      this.src.title = ''
    },
    removeCurriculum(curriculumIndex) {
      this.payload.curriculum.splice(curriculumIndex, 1)
    },
    addList(curriculumIndex) {
      if (this.src.currentList) {
        this.payload.curriculum[curriculumIndex].lists.push(this.src.currentList)
        this.src.currentList = ''
      }
    },
    removeList(curriculumIndex, listItemIndex) {
      this.payload.curriculum[curriculumIndex].lists.splice(listItemIndex, 1)
    },
    async updateCourse() {
      this.isSubmitting = true
      console.log('updating course')
      const newPayload = {
        ...this.payload
      }

      // if (newPayload.from) newPayload.from = new Date(newPayload.from).toISOString()
      // if (newPayload.to) newPayload.to = new Date(newPayload.to).toISOString()
      newPayload.curriculum = JSON.stringify(newPayload.curriculum)
      newPayload.id = this.id
      try {
        let res = await courseService.updateCourse(newPayload)
        console.log(res)
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Success",
          customClass: "sweetalertsuccess",
          showConfirmButton: false,
          timer: 1500,
        });

        clearTimeout(3000);
        setTimeout(() => {
          this.$router.push({ name: 'CoursesRegister' })
        }, 3000);
      } catch (err) {
        console.log(err)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error',
          confirmButtonText: 'OK',
        });
      } finally {
        this.isSubmitting = false
      }
    },
    setDate(data) {
      this.payload.class_type = data.class_type
      this.payload.year_level = data.year_level
      this.payload.fromMonth = data.fromMonth
      this.payload.toMonth = data.toMonth
      this.payload.intake = data.intake
      this.payload.order = data.list_order
      this.payload.curriculum = JSON.parse(data.curriculum)
    },
    async fetchCourse() {
      let res = await courseService.getCourse({ id: this.id })
      console.log(res)
      this.setDate(res)
    },
    goBack() {
      window.location.assign("/admin/courses-register");
    },
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchCourse()
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped></style>