<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <template v-else>
    <div class="row">
      <div class="col-12">
        <div class="row gx-0" style="background-color:rgb(244, 234, 223)">
          <BookingSlotList />
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import axios from 'axios';
import { DeviceUUID } from 'device-uuid';
import BookingSlotList from './BookingSlotList.vue';
import Loading from '@/components/general/Loading.vue';

export default {
  components: {
    Loading,
    BookingSlotList
  },

  data(){
    return{
      
      link: "https://backend.smkedugroup.com",
      isLoading: true,
      userID: '',
      localtoken: '',
      deviceID: '',
    }
  },

  methods: {
    alreadyLogin() {
      let article = {
        userID:this.userID,
        localtoken:this.localtoken ,
        deviceID:this.deviceID,
      };

      axios.post(this.link+"/api/alreadyLogin", article)
        .then(response => {
          if(response.data.state=="failed") {
            window.location.assign("/login")
          } else if(response.data.state=="success") {
            localStorage.setItem("token",response.data.Token);
            localStorage.setItem("deviceID", response.data.deviceID);
            localStorage.setItem("userID", response.data.userid);
          }
        })
        .catch(error => {
          window.location.assign("/login");
          this.errorMessage = error.message;
          console.error("There was an error!", error);
       })
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      this.deviceID = localStorage.getItem("deviceID") ?? new DeviceUUID().get();
      this.localtoken = localStorage.getItem("token") ?? "Null";
      this.userID = localStorage.getItem("userID") ?? "Null";

      this.alreadyLogin();
    } catch (error) {
      console.log("error mounting admin user page: ", error);
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped></style>
