<template>
  <div class="card mt-2">
    <div class="card-body">
      <div class="row ">
        <div class="col-sm-12 ms-1">
          <div class="row d-flex mt-5">
            <div class="container" style="max-width: 100%;">
              <h4 class="mb-3">Arrange the teacher by drag and drop:</h4>
              <template v-if="sortedArray?.length > 0">
                <draggable
                  v-model="sortedArray"
                  group="people"
                  @start="drag=true"
                  @end="drag=false"
                  tag="ol"
                  item-key="id"
                  class="list-group list-group-numbered"
                  @change="handleChange"
                >
                  <template #item="{ element }">
                    <li class="list-group-item text-start my-1 border-top">
                      {{ element.name }} - {{ element.position }}
                    </li>
                  </template>
                </draggable>
              </template>
              <div v-else>
                <div>No teacher record exist!</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  props: {
    list: {
      type: Array,
      default: [],
    }
  },

  components: {
    draggable,
  },

  data() {
    return {
      drag: false,
      sortedArray: this.list,
    }
  },

  emits: ["onChange"],

  methods: {
    handleChange() {
      this.$emit("onChange", {
        list: this.sortedArray
      });
    }
  },
}
</script>
