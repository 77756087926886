<template>
  <h2 class="mtext text-center">Shwe Maw Kun School Network</h2>
  <div class="row gx-0" style="background: #663399">
    <div class="col-12 col-md-4">
      <div class="school_container">
        <!-- <img src="@/assets/img/1.jpg" style="width:100%;height:100%;object-fit: cover;" alt=""> -->
        <div class="school-img img-1"></div>
        <div class="our-school-info-container">
          <router-link :to="{ name: 'AcademicWeekdayPreSchool' }">
            <div role="link" v-if="font_type == 1"
              class="img-label-container" style="position: absolute; bottom: 0; right: 0; left: 0">
              <div class="our-school-content">
                <p class="our-school-title mb-2">PRE-SCHOOL</p>
                <p class="our-school-text">Age 3-5</p>
              </div>
            </div>
            <div role="link" v-if="font_type == 2"
              class="img-label-container" style="position: absolute; bottom: 0; right: 0; left: 0">
              <div class="our-school-content">
                <p class="our-school-title mb-2">မူလတန်းကြိုကျောင်း</p>
                <p class="our-school-text">အသက် ၃ - ၅</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4">
      <div class="school_container">
        <!-- <img src="@/assets/img/4.jpg" style="width:100%;height:100%;object-fit: cover;" alt=""> -->
        <div class="school-img img-2"></div>
        <div class="our-school-info-container">
          <router-link :to="{ name: 'AcademicWeekdayPrimary' }">
            <div role="link" v-if="font_type == 1"
              class="img-label-container" style="position: absolute; bottom: 0; right: 0; left: 0">
              <div class="our-school-content">
                <p class="our-school-title mb-2">PRIMARY</p>
                <p class="our-school-text">Age 5-12</p>
              </div>
            </div>
            <div role="link" v-if="font_type == 2"
              class="img-label-container" style="position: absolute; bottom: 0; right: 0; left: 0">
              <div class="our-school-content">
                <p class="our-school-title mb-2">မူလတန်း</p>
                <p class="our-school-text">အသက် ၅ - ၁၂</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!--    <div class="col d-flex justify-content-center">-->
    <!--      <div class="school_container" style="min-width:250px;max-width: 400px;">-->
    <!--          <img src="@/assets/img/2.jpg" style="width:100%;height:100%;" alt="">-->
    <!--        <div class="our_school_div_letter">-->
    <!--            <p class="our-school-title mb-2"> LOWER SECONDARY</p>-->
    <!--            <p>Age 12-14</p>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="col-12 col-md-4">
      <div class="school_container">
        <!-- <img src="@/assets/img/2.jpg" style="width:100%;height:100%;object-fit: cover;" alt=""> -->
        <div class="school-img img-3"></div>
        <div class="our-school-info-container">
          <router-link
            :to="{ name: 'AcademicWeekdaySecondary' }">
            <div role="link" v-if="font_type == 1" class="img-label-container"
              style="position: absolute; bottom: 0; right: 0; left: 0">
              <div class="our-school-content">
                <p class="our-school-title mb-2">MIDDLE SCHOOL</p>
                <p class="our-school-text">Age 12-14</p>
              </div>
            </div>
            <div role="link" v-if="font_type == 2"
              class="img-label-container" style="position: absolute; bottom: 0; right: 0; left: 0">
              <div class="our-school-content">
                <p class="our-school-title mb-2">အလယ်တန်း</p>
                <p class="our-school-text">အသက် ၁၂ - ၁၄</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="school_container">
        <!-- <img src="@/assets/img/3.jpg" style="width:100%;height:100%;object-fit: cover;" alt=""> -->
        <div class="school-img img-4"></div>
        <div class="our-school-info-container">

          <!-- <div v-if="font_type == 1" class="img-label-container"
              style="position: absolute; bottom: 0; right: 0; left: 0">
              <p class="our-school-title mb-2">Start and end-of-day activities</p>
              <p class="our-school-text">Age 3-18</p>
            </div> -->
          <div class="img-label-container" style="position: absolute; bottom: 0; right: 0; left: 0">
            <div class="d-flex justify-content-around">
              <router-link role="link"
                :to="{ name: 'AcademicWeekendPreSchool' }">
                <div class="our-school-content" role="link">
                  <template v-if="font_type == 1">
                    <p class="our-school-title mb-2">
                      PRE-SCHOOL
                    </p>
                    <p class="our-school-text">Age 3 - 5</p>
                  </template>
                  <template v-if="font_type == 2">
                    <p class="our-school-title mb-2">
                      မူလတန်းကြိုကျောင်း
                    </p>
                    <p class="our-school-text">အသက် ၃ - ၅</p>
                  </template>
                </div>
              </router-link>
              <router-link role="link"
                :to="{ name: 'AcademicWeekendPrimary' }">
                <div class="our-school-content" role="link">
                  <template v-if="font_type == 1">
                    <p class="our-school-title mb-2">
                      PRIMARY
                    </p>
                    <p class="our-school-text">Age 5 - 12</p>
                  </template>
                  <template v-if="font_type == 2">
                    <p class="our-school-title mb-2">
                      မူလတန်း
                    </p>
                    <p class="our-school-text">အသက် ၅ - ၁၂</p>
                  </template>
                </div>
              </router-link>
              <router-link role="link"
                :to="{ name: 'AcademicWeekendSecondary' }">
                <div class="our-school-content" role="link">
                  <template v-if="font_type == 1">
                    <p class="our-school-title mb-2">
                      MIDDLE SCHOOL
                    </p>
                    <p class="our-school-text">Age 12 - 14</p>
                  </template>
                  <template v-if="font_type == 2">
                    <p class="our-school-title mb-2">
                      အလယ်တန်း
                    </p>
                    <p class="our-school-text">အသက် ၁၂ - ၁၄</p>
                  </template>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
};
</script>

<style lang="scss" scoped>
.school_container {
  // margin-left: 5px;
  // margin-right: 5px;
  position: relative;
}

.our_school_div_letter {
  bottom: 0;
  color: white;
  left: 50%;
  transform: translate(-50%, -0%);
  position: absolute;
}

.school-img {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.img-1 {
  background-image: url("@/assets/img/home/our-school/Pre-School.png");
}

.img-2 {
  background-image: url("@/assets/img/home/our-school/Primary.png");
}

.img-3 {
  background-image: url("@/assets/img/home/our-school/Secondary-School.png");
}

.img-4 {
  height: 600px !important;
  background-image: url("@/assets/img/home/our-school/Summer-School.jpg");
}

.img-label-container {
  padding: 10px;
  background-color: #a19e9e80;
}

.our-school-title {
  transition: color !important;
}

.our-school-content {
  &:hover {
    cursor: pointer;

    p {
      color: var(--cus-primary) !important;
    }
  }
}
</style>
