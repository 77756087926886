<script>
import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/jquery/dist/jquery.js";
import "@fortawesome/free-solid-svg-icons";
import Loading from "./components/general/Loading.vue";
import Navbar from "@/components/Navbars/Navbar.vue";
import SubNavbar from "@/components/Navbars/SubNavbar.vue";
import AcademicBar from "@/components/Navbars/AcademicBar.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import { DeviceUUID } from 'device-uuid';

export default {
  components: {
    Navbar,
    SubNavbar,
    AcademicBar,
    Footer,
    Loading
  },
  data() {
    return {
      link: 'https://backend.smkedugroup.com',
      path: false,
      schtype: "",
      font_type: 1,
      path1: null,
      isSubNavbarOpen: false,
      isAcademicBarOpen: false,
      navOpened: false,
      viewsList: ["Academic"],
      isSuperAdmin: 'false',
    };
  },

  // computed: {
  //   isFooterOpen() {

  //   }
  // },
  mounted() {
    this.alreadyLogin();
    if (localStorage.getItem("font_type")) {
      this.$store.dispatch("changeFont", localStorage.getItem("font_type"));
      this.font_type = localStorage.getItem("font_type");
    } else {
      this.$store.dispatch("changeFont", 2);
      localStorage.setItem("font_type", 2);
      this.font_type = 2;
    }
    // alert(localStorage.getItem("font_type"));
  },
  methods: {
    font_change: function () {
      this.$store.dispatch("changeFont", this.font_type);
      localStorage.setItem("font_type", this.font_type);
    },
    closeSubNavbars: function () {
      this.isSubNavbarOpen = false;
      this.isAcademicBarOpen = false;
    },
    toggleAcademicBar: function () {
      if (this.isSubNavbarOpen) {
        this.isSubNavbarOpen = false;
      }
      this.isAcademicBarOpen = !this.isAcademicBarOpen;
    },
    toggleSubNavbar: function () {
      if (this.isAcademicBarOpen) {
        this.isAcademicBarOpen = false;
      }
      this.isSubNavbarOpen = !this.isSubNavbarOpen;
    },
    alreadyLogin() {
      let article = {
        deviceID: localStorage.getItem("deviceID") ?? new DeviceUUID().get(),
        localtoken: localStorage.getItem("token") ?? "Null",
        userID: localStorage.getItem("userID") ?? "Null",
      };

      axios
        .post(this.link + "/api/alreadyLogin", article)
        .then((response) => {
          if (response.data.state == "success") {
            this.isSuperAdmin = response.data.isSuperAdmin;
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
  },
  watch: {
    $route(to, from) {
      var currentUrl = window.location.pathname;
      var Url = currentUrl.split("/");
      if (window.location.pathname.split("/")[1] == "") {
        this.path1 = true;
      } else {
        this.path1 = false;
      }
      //  alert(currentUrl);
      if (Url[1] == "admin") {
        this.path = true;
      } else {
        this.path = false;
      }

      // $(window).ready(function () {
      //   $("#see").show();
      //   $("#hide").hide();

      //   setTimeout(function () {
      //     $("#hide").show();
      //     $("#see").hide();
      //   }, 3000);
      // });

      // ak
      let arr = to.matched.map((item) => item.name);
      if (!arr.includes("Academic")) this.isAcademicBarOpen = false;
      if (!arr.includes("Admission")) this.isSubNavbarOpen = false;
    },
  },
};
</script>

<template>
  <div class="nav_all">
    <div class="nav_shade">
      <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" style="margin-left: 50px" href="#">
            <div class="row">
              <div class="col">
                <img class="navlogo" src="./assets/img/logo.png" alt="" />
              </div>
              <div class="col">
                <router-link :to="{ name: 'Home', params: { userId: 1 } }" class="nav-link" aria-current="page">
                  <img src="./assets/img/navlogo.png" style="width: 150px" alt="" />
                </router-link>
              </div>
            </div>
          </a>
          <a class="navbar-brand ms-auto" href="#">
            <div class="row">
              <div class="col">
                <select name="" class="form-control rounded-pill text-center" v-model="font_type" @change="font_change"
                  style="border-color: purple" id="">
                  <option value="1" style="
                      background-image: url('@/assets/img/myanmarflag.png');
                      background-repeat: no-repeat;
                      background-attachment: fixed;
                      background-position: center;
                    ">
                    English Language
                  </option>
                  <option value="2">Myanmar Language</option>
                </select>
                <h5 class="mt-2">
                  <a target="_blank"
                    href="https://www.facebook.com/MawKunInternationalEducationGroupMyanmar?mibextid=ZbWKwL"
                    class="about_contact">
                    <font-awesome-icon class="m-1" style="width: 35px; height: 35px; color: purple"
                      icon="fa-brands fa-facebook" />
                  </a>
                  <a target="_blank" href="https://instagram.com/smk_education_group?igshid=YmMyMTA2M2Y="
                    class="about_contact">
                    <font-awesome-icon class="m-1" style="
                        width: 19px;
                        height: 19px;
                        color: white;
                        border: 0px;
                        border-style: solid;
                        border-radius: 40px;
                        background-color: purple;
                        padding: 8px;
                      " icon="fa-brands fa-instagram" />
                  </a>
                  <a target="_blank" href="https://www.linkedin.com/company/shwe-maw-kun-education-group"
                    class="about_contact">
                    <font-awesome-icon class="m-1" style="
                        width: 19px;
                        height: 19px;
                        color: white;
                        border: 0px;
                        border-style: solid;
                        border-radius: 40px;
                        background-color: purple;
                        padding: 8px;
                      " icon="fa-brands fa-linkedin" />
                  </a>
                  <a target="_blank"
                    href="https://invite.viber.com/?g2=AQA33LOWH3ZcM0%2BtyMUiATIehtC7nm7HaMwzBZfKJKLmbyjENzS88RQTRgOIJAKD"
                    class="about_contact">
                    <font-awesome-icon class="m-1" style="
                        width: 19px;
                        height: 19px;
                        color: white;
                        border: 0px;
                        border-style: solid;
                        border-radius: 40px;
                        background-color: purple;
                        padding: 8px;
                      " icon="fa-brands fa-whatsapp" />
                  </a>
                  <a target="_blank" href="https://youtube.com/@shwemawkunsmkinternational9122" class="about_contact">
                    <font-awesome-icon class="m-1" style="
                        width: 19px;
                        height: 19px;
                        color: white;
                        border: 0px;
                        border-style: solid;
                        border-radius: 40px;
                        background-color: purple;
                        padding: 8px;
                      " icon="fa-brands fa-youtube" />
                  </a>
                  <a target="_blank" href="https://t.me/shwemawkuneducationgroup" class="about_contact">
                    <font-awesome-icon class="m-1" style="
                        width: 35px;
                        height: 35px;
                        color: purple;
                        border: 0px;
                        border-style: solid;
                        border-radius: 40px;
                        background-color: white;
                      " icon="fa-brands fa-telegram" />
                  </a>
                </h5>
              </div>
            </div>
          </a>
        </div>
      </nav>
    </div>
  </div>

  <div v-if="!path" class="sticky-top">
    <!-- purple nav -->
    <Navbar @openSubNavbar="toggleSubNavbar" @openAcademicBar="toggleAcademicBar" :font_type="font_type"
      :schtype="schtype"></Navbar>

    <!-- gold nav -->
    <template v-if="isSubNavbarOpen">
      <SubNavbar :font_type="font_type"></SubNavbar>
    </template>

    <template v-if="isAcademicBarOpen">
      <AcademicBar :font_type="font_type"></AcademicBar>
    </template>
  </div>

  <div v-if="path">
    <div class="nav_all">
      <div class="nav_shade">
        <nav class="navbar navbar-expand-lg nav_in">
          <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
              <ul class="navbar-nav mx-auto">
                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'waitinglistview', params: { userId: 3 } }" class="cus_nav">Waiting List
                    <span class="nav_hidden_text">View</span>
                  </router-link>
                </li>
                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'EventRegister', params: { userId: 3 } }" class="cus_nav">Event <span
                      class="nav_hidden_text">Register</span>
                  </router-link>
                </li>

                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'ContactusView', params: { userId: 3 } }" class="cus_nav">Contact us <span
                      class="nav_hidden_text">View</span>
                  </router-link>
                </li>

                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'AdminAppointmentEnquiry', params: { userId: 3 } }" class="cus_nav">Appointment <span
                      class="nav_hidden_text">List</span>
                  </router-link>
                </li>

                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'AdminBookingSlot', params: { userId: 3 } }" class="cus_nav">Booking <span
                      class="nav_hidden_text">Slot</span>
                  </router-link>
                </li>

                <!-- <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'Calendar', params: { userId: 3 } }" class="cus_nav">Calendar <span
                      class="nav_hidden_text">Register</span>
                  </router-link>
                </li> -->

                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'FeesRegister', params: { userId: 3 } }" class="cus_nav">Fees <span
                      class="nav_hidden_text">Register</span>
                  </router-link>
                </li>

                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'CoursesRegister', params: { userId: 3 } }" class="cus_nav">Course
                    <span class="nav_hidden_text">Register</span></router-link>
                </li>

                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'CareerView', params: { userId: 3 } }" class="cus_nav">Career
                    <span class="nav_hidden_text">View</span></router-link>
                </li>

                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'TeacherRegister', params: { userId: 3 } }" class="cus_nav">Teacher <span
                      class="nav_hidden_text">Register</span>
                  </router-link>
                </li>
                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'TeacherCategory', params: { userId: 4 } }" class="cus_nav">Teacher <span
                      class="nav_hidden_text">Category</span>
                  </router-link>
                </li>
                <!-- add superadmin permission condition -->
                <li class="nav-item mt-3 mb-2" v-if="isSuperAdmin == 'true'">
                  <router-link :to="{ name: 'AdminUser', params: { userId: 4 } }" class="cus_nav">User <span
                      class="nav_hidden_text">Register</span>
                  </router-link>
                </li>
                <li class="nav-item mt-3 mb-2">
                  <router-link :to="{ name: 'logout', params: { userId: 5 } }"
                    class="btn btn-success">Logout</router-link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>

  <!--  #f3cd5d -->
  <template v-if="this.$store.state.isAdminPageLoading">
    <div id="see">
      <Loading />
    </div>
  </template>
  <template v-else>
    <div id="hide" style="background-color: #ffffff">
      <router-view> </router-view>
    </div>
  </template>
  <Footer v-if="this.$store.state.footer" :font_type="font_type" :path1="path1"></Footer>
</template>

<style lang="scss">
html,
* {
  font-family: "Golos Text", sans-serif;
  scroll-padding-top: 110px;
}

:root {
  --cus-primary: #7f3874;
  --cus-secondary: #f2bc00;
  --cus-secondary-bold: #db9000;
  --cus-secondary-bold-2: #db3e00;
  --cus-dark: #000000;
  --cus-white: #ffffff;
  --cus-grey: #e3e1e2;

  // font size
  --cus-fs-xxxsmall: 6px;
  --cus-fs-xxsmall: 8px;
  --cus-fs-xsmall: 10px;
  --cus-fs-small: 12px;
  --cus-fs-normal: 16px;
  --cus-fs-large: 20px;
  --cus-fs-x-large: 24px;
  --cus-fs-xx-large: 28px;
  --cus-fs-xxx-large: 32px;
  --cus-fs-xxxx-large: 38px;
  --cus-fs-xxxxx-large: 42px;

  // font weight
  --cus-fw-semibold: 600;

  // border radisu
  --cus-border-radius: 15px;
}

// helpers
.hover-cursor {
  cursor: pointer;
}

.bg-cus-primary {
  background-color: var(--cus-primary);
}

.bg-cus-secondary {
  background-color: var(--cus-secondary);
}

.text-cus-primary {
  color: var(--cus-primary);
}

.text-cus-secondary {
  color: var(--cus-secondary);
}

// .cus-container {
//   max-width: 1082px;
//   margin-left: auto;
//   margin-right: auto;
// }

// helpers end

.loaderphoto {
  margin: 20vh;
  width: 300px;

  z-index: 200;
  border: 0px;
  border-style: none;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.75);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
  background-color: var(--cus-primary);

  a {
    font-size: 17px;
    color: #ffffff;

    &.router-link-exact-active {
      color: gold;
    }
  }
}

.cus_nav {
  display: inline-block;
  padding: 5px 8px;
  text-decoration: none;
  border: 0px;
  font-size: 20px;
  color: #ffffff;
  // border-bottom: 1px;
  border-style: solid;
  border-radius: 5px;
  transition: 0.5s;
}

.cus_nav:hover {
  // padding: 10px;
  animation-name: color;
  animation-duration: 1s;
  color: purple;
  background-color: rgb(255, 255, 255);
  // box-shadow: 1px 3px 3px blue;
  // backdrop-filter: blur(10px);
}

.cus_nav:hover .nav_hidden_text {
  animation-name: example;
  animation-duration: 1s;
  visibility: visible;
  width: 100%;
}

.cus_sub_nav {
  background: var(--cus-secondary) !important;
  font-size: 20px;

  li a {
    max-width: 320px;
    color: var(--cus-dark);
  }

  li:not(:last-child) a {
    margin-right: 30px;
  }
}

.nav_hidden_text {
  width: 0px;

  visibility: hidden;
}

@keyframes example {
  0% {
    width: 25px;
  }

  100% {
    width: 50px;
  }
}

@keyframes color {
  0% {
    background-color: rgba(255, 255, 255, 0);
    // box-shadow: 1px 3px 3px rgba(0, 0, 255, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
    // box-shadow: 1px 3px 3px blue;
  }
}

// bootstrap fix
// @media (min-width: 1200px) {
//   .container {
//     max-width: 1200px;
//   }
// }
</style>
