<template>
  <div>
    <!-- part 1 -->
    <section class="section_1 pb-5">
      <Hero />
    </section>

    <section class="pb-5">
      <div class="container">
        <About :font_type="font_type"></About>
      </div>
    </section>

    <section class="pb-5">
      <div class="keyfact">
        <div class="px-3 mx-sm-5 px-ms-5">
          <KeyFact :font_type="font_type"></KeyFact>
        </div>
      </div>
    </section>

    <!-- <section class="m-5 d-flex justify-content-center keyfact">
    <div>
      <h1 CLASS="m-5 ps-5" style="color:goldenrod;font-size:50px;text-align:left;">KEY FACTS ABOUT OUR SCHOOL </h1>
      <div class="container">
        <div style="position:relative" class="">
          <div class="row g-3">
            <div class="col-12">
              <div class="row g-3 align-content-stretch justify-content-center">
                <div class="col-3 d-flex">
                  <div class="row g-3 h-100">
                    <div class="col-12">
                      <div
                        style="border:1px black;border-style:solid;border-radius:20px;padding:40px;height: 100%;">
                        <h5 style="color:purple">LANGUAGES TAUGHT</h5>
                        <P>Mandarin,Japanese</P>
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        style="border:1px black;border-style:solid;border-radius:20px;padding:20px;height: 100%;">
                        <H4 style="color:goldenrod">AFFORDABLE <BR /> SCHOOL FEES</h4>
                        <img src="../assets/img/money.png" style="width:100px;height: 91px;" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-5 d-flex">
                  <div class="row g-3 h-100">
                      <div class="col-5"> 
                        <div
                          style="border:1px black;border-style:solid;border-radius:20px;padding:20px;height:100%;">
                          <H5 style="color:purple" class="mb-4">CURRICULUM </h5>
                          <div class="row gx-0 ">
                            <div class="col-6 pb-1">
                              <img data-bs-toggle="tooltip" data-bs-placement="top" v-tippy="'Myanmar'"
                                data-bs-custom-class="custom-tooltip" data-bs-title="Myanmar"
                                src="./../assets/img/myanmarflag.png" style="width:90%;" alt="">
                            </div>
                            <div class="col-6 pb-1">
                              <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                data-bs-title="Singapore" v-tippy="'Singapore'" src="./../assets/img/sgflag.png"
                                style="width:90%;" alt="">

                            </div>
                            <div class="col-6 p-1">
                              <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                data-bs-title="United Kingdom" v-tippy="'United Kingdom'" src="./../assets/img/ukflag.png"
                                style="width:90%;" alt="">

                            </div>
                            <div class="col-6 p-1">
                              <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                data-bs-title="United State " v-tippy="'United State '" src="./../assets/img/usflag.png"
                                style="width:90%;" alt="">

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-7">
                        <div
                          style="border:1px black;border-style:solid;border-radius:20px;padding:20px;" class="h-100">

                          <img src="../assets/img/groupteaching.png" style="width:50%;height: 80px;" alt="">

                          <H5 class="mt-2" style="font-size:20px;color: purple;">STUDENT TO <BR /> TEACHER RATIO</h5>
                          <h2 style="color:goldenrod;font-size: 50px;font-weight: 800;">7:1</h2>
                        </div>
                      </div>
                      <div class="col-12">
                          <div class="h-100"
                            style="border:1px black;border-style:solid;border-radius:20px;padding:20px;">
                            <h4 style="color:purple">LANGUAGE OF INSTRUCTION</h4>
                            <P style="color:purple" class="text-center">English</P>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 d-flex">
                  <div class="row g-3 h-100">
                    <div class="col-12">
                      <div
                        style="border:1px black;border-style:solid;border-radius:20px;padding:20px;" class="h-100">
                        <div class="row g-3">
                          <div class="col-8">
                            <h5 class="mt-3" style="color:goldenrod"> AGE RANGE</h5>
                            <h2 style="font-size:50px;font-weight:900; color:purple">3-18</h2>
                          </div>
                          <div class="col-4 ">
                            <img src="../assets/img/takegradu.png" style="width:100%;height: 100px;" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                        <div
                          style="border:1px black;border-style:solid;border-radius:20px;padding:40px;" class="h-100">
                          <H5 CLASS="mb-3" style="color:purple">DIGITAL LITERACY </h5>
                          <div class="row gx-0">
                            <div class="col-4 ">
                              <img src="../assets/img/usingtech.png" style="width:100%;" alt="">
                            </div>
                            <div class="col-4">
                              <img src="../assets/img/monitor.png" style="width:100%;" alt="">
                            </div>
                            <div class="col-4">
                              <img src="../assets/img/touchtablet.png" style="width:100%;" alt="">
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row g-3  justify-content-center">
                  <div class="col-4">
                    <div style="border:1px black;border-style:solid;border-radius:20px;padding:20px;">
                      <div class="row justify-content-center">
                        <div class="col-12">
                          <H5 style="color:purple" class="mb-3">CO-CURRICULAR ACTIVITIES</h5>
                        </div>
                        <div class="col-3">
                          <img v-tippy="'Arts and Craft!'" data-bs-toggle="tooltip" data-bs-placement="top" title="Arts and Craft"
                          alt="hello" src="./../assets/img/painting.png" style="width:50px;height: 50px;margin:5px">
                        </div>
                        <div class="col-3">
                          <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                            data-bs-title="Dance" v-tippy="'Dance'" src="./../assets/img/dancing.png"
                            style="width:50px;height: 50px;margin:5px" alt="">
                        </div>
                        <div class="col-3">
                        <img v-tippy="'Life Skills'" data-bs-toggle="tooltip" data-bs-placement="top"
                          data-bs-custom-class="custom-tooltip" data-bs-title="Life Skills" src="./../assets/img/shouting.png"
                          style="width:50px;height: 50px;margin:5px" alt="">
                          </div>
                        <div class="col-3">
                        <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                          data-bs-title="Music " v-tippy="'Music'" src="./../assets/img/singing.png"
                          style="width:50px;height: 50px;margin:5px" alt="">
                          </div>
                        <div class="col-3">
                        <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                          data-bs-title="Yoga" v-tippy="'Yoga'" src="./../assets/img/sitting.png"
                          style="width:50px;height: 50px;margin:5px" alt="">
                          </div>
                        <div class="col">
                        <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                          data-bs-title="STEAM" v-tippy="'STEAM'" src="./../assets/img/steamlet.png"
                          style="width:150px;height: 50px;margin:15px" alt="">
                          </div>
                        <div class="col-3">
                        <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                          data-bs-title="Mindfulness" v-tippy="'Mindfulness'" src="./../assets/img/brainstorming.png"
                          style="width:50px;height: 50px;margin:5px" alt="">
                          </div>
                        <div class="col-3">
                        <img data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                          data-bs-title="Physical Education" v-tippy="'Physical Education'" src="./../assets/img/running.png"
                          style="width:50px;height: 50px;margin:5px" alt="">
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div style="border:1px black;border-style:solid;border-radius:20px;padding:20px;height: 100%;">
                      <H5 style="color:purple" class="mb-3">INTERNATIONAL <br> OLYMPIAD EXAM CENTER</H5>
                      <img src="../assets/img/sitandwriting.png" style="width:100px;height: 100px;" alt="">
                      <img src="../assets/img/sitandwriting.png" style="width:100px;height: 100px;" alt="">

                    </div>
                  </div>
                  <div class="col-3">
                    <div style="border:1px black;border-style:solid;border-radius:20px;padding:20px;height: 100%;">
                      <h5 style="color:purple" class="mb-3">GENERATIONS OF <BR /> EXCELLENCE </h5>
                      <img src=".././assets/img/badge.png" style="width:100px;height: 100px;" alt="">
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div style="top:-225px;right:50px; position:absolute">
            <img src="../assets/img/sit_home.png" style="width:280px;height:280px;" alt="">
          </div>
        </div>
      </div>
    </div>
  </section> -->

    <!-- <section class="cus-key-fact">
    <div class="container">
      <h1>KEY FACTS ABOUT OUR SCHOOL </h1>

      <div class="row g-1 g-md-4 mb-4">
        <div class="col-3">
          <div class="cus-fact-item">
            <h5>Language Taught</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="cus-fact-item">
            <h5>Language Taught</h5>
            <p>Mandarin, Japanese</p>
          </div>
        </div>
        <div class="col-3">
          <div class="cus-fact-item">
            <h5>Language Taught</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="cus-fact-item">
            <h5>Language Taught</h5>
          </div>
        </div>
      </div>

      <div class="row g-1 g-md-4 mb-4">
        <div class="col-3">
          <div class="cus-fact-item">
              <h5>Language Taught</h5>
          </div>
        </div>
        <div class="col-6">
          <div class="cus-fact-item">
              <h5>Language Taught</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="cus-fact-item">
              <h5>Language Taught</h5>
          </div>
        </div>
      </div>

      <div class="row g-1 g-md-4">
        <div class="col-4">
          <div class="cus-fact-item">
              <h5>Language Taught</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="cus-fact-item">
              <h5>Language Taught</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="cus-fact-item">
              <h5>Language Taught</h5>
          </div>
        </div>
      </div>
    </div>
  </section> -->

    <!-- part 3 -->

    <section class="section_6 pb-5">
      <div class="container Part6">
        <Testimonial></Testimonial>
      </div>
    </section>

    <section class="pb-5">
      <div class="container">
        <OurSchool />
      </div>
    </section>

    <section class="pb-5">
      <div class="container">
        <div>
          <h1 v-if="font_type == 1" class="text-center mtext mb-3">
            Freedom of expression:
          </h1>
          <h3 v-if="font_type == 2" class="text-center mtext mb-3">
            လှုပ်ရှားမှု ပုံရိပ်များ
          </h3>
        </div>
        <div class="row gx-lg-3 gy-3 gy-lg-0 justify-content-center">
          <div
            class="col-12 col-md-4 colevent mx-auto"
            :key="index"
            v-for="(dat2, index) in data2_1"
          >
            <div
              class="eventdivall shadow"
              style="border: 0px; border-style: solid; border-radius: 20px"
            >
              <div class="news_div_top">
                <!-- <span style="text-align:right; display: block;"> {{dat2.time}}</span> -->
                <h3 style="color: white; font-weight: 800">{{ dat2.name }}</h3>
                <div class="d-inline" style="">
                  <span style="color: white">
                    <span v-for="(dat_2, datint) in dat2.content" :key="datint">
                      <span v-if="datint < 100">{{ dat_2 }} </span>
                    </span>
                  </span>
                  <div class="collapse" :id="idseemore + dat2.id + list">
                    <span
                      style="color: white"
                      v-for="(dat_2, datint) in dat2.content"
                      :key="datint"
                    >
                      <span v-if="datint >= 100">{{ dat_2 }} </span>
                    </span>
                  </div>
                </div>
                <br />
                <button
                  class="morepara"
                  v-if="Object.keys(dat2.content).length > 0"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="seemore + dat2.id + list"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  See More
                </button>
              </div>
              <div class="news_div_bot m-3">
                <img class="eventimg" :src="dat2.eventimg" style="" alt="" />
              </div>
              <div class="">
                <router-link :to="{ name: 'NewsAndActivities' }" class="btn btn-warning">
                  Learn More</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-5">
      <div class="container Part5">
        <Team :data2="data2"></Team>
      </div>
    </section>
    <!-- Part 6 -->

    <!-- <section>
  <div>
  <VueSlickCarousel :arrows="true" :dots="true">
   <div v-for="(feed, index) in instafeed">
    <img :src="feed.media_url" alt />
   </div>
  </VueSlickCarousel>
 </div>
</section> -->

    <!-- part 7 -->
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import About from "@/components/Home/About.vue";
import KeyFact from "@/components/Home/KeyFact.vue";
import Testimonial from "@/components/Home/Testimonial.vue";
import Team from "@/components/Home/Team.vue";
import Hero from "@/components/Home/Hero.vue";
import OurSchool from "@/components/Home/OurSchool.vue";
import axios from "axios";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import $ from "jquery";

export default {
  name: "HomeView",
  components: {
    VueSlickCarousel,
    About,
    KeyFact,
    Testimonial,
    Team,
    OurSchool,
    Hero,
  },
  data() {
    return {
      // font_type: localStorage.getItem("font_type"),
      value: "",
      data2: null,
      data2_1: [],
      
      link: "https://backend.smkedugroup.com",
      commentcontent: "",
      comment2: "comment",
      colap: "#colap",
      colapid: "colap",
      seemore: "#seemore",
      idseemore: "seemore",
      moredetail: "#post",
      idmoredetail: "post",
      model: "model",
      list: "list",
      grid: "grid",
    };
  },
  computed: {
    font_type() {
      return this.$store.state.font_type;
    },
  },
  methods: {
    show() {
      axios
        .get(this.link + "/api/teacher")
        .then((response) => {
          this.data2 = response.data.filter((el) => el.isDisplay == 1);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    show2() {
      axios
        .get(this.link + "/api/eventinput")
        .then((response) => {
          let data11 = [];
          for (let i = 0; i < response.data.length; i++) {
            if (i < 3) {
              this.data2_1.push(response.data[i]);
            }
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    comment1(id, type) {
      let article = {
        eventID: id,
        commentcontent: $("#" + type + "comment" + id).val(),
      };
      axios
        .post(this.link + "/api/comment", article)
        .then((response) => {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success",
            customClass: "sweetalertsuccess",
            showConfirmButton: false,
            timer: 1500,
          });
          $("#" + type + "comment" + id).val("");
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
      this.show();
      this.show();
      this.show();
      // this.showcmt();
      // this.showcmt();
      // this.showcmt();
    },
    like(id) {
      const islike = localStorage.getItem("isLike" + id);
      if (islike == "true=" + id) {
        alert("you already liked it");
      } else {
        axios
          .post(this.link + "/api/like/" + id)
          .then((response) => console.log(response))
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
        localStorage.setItem("isLike" + id, "true=" + id);
        this.show();
        this.show();
        this.show();
        // this.showcmt();
        // this.showcmt();
        // this.showcmt();
      }
    },
  },
  updated() {
    $(".comment").click(function () {
      //$('.hideme').hide();
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");

        $(".comment_section").slideUp("slow");
      } else {
        $(".comment_section").slideUp("slow");
        $(".comment").removeClass("active");
        $(this).addClass("active");

        $(this).next().filter(".comment_section").slideDown("slow");
      }
    });

    $(".morepara").click(function (event) {
      //$('.hideme').hide();
      if ($(this).hasClass("active")) {
        $(this).text("See More");
        event.target.classList.remove("active");

        $(".showpara").slideUp("slow");
      } else {
        $(".showpara").slideUp("slow");
        $(".morepara").removeClass("active");
        $(".morepara").text("See More");

        event.target.classList.add("active");
        $(this).prev().filter(".showpara").slideDown("slow");
        $(this).text("See Less");
      }
    });
  },
  mounted() {
    this.show();
    this.show2();
  },
};
</script>

<style lang="scss">
:root {
  // --img: linear-gradient(120deg, rgba(246,211,101,0.51) 0%, rgba(253,160,133,0.74) 100%);
  --br: linear-gradient(120deg, #671259 0%, #ffffff 100%);
  --bb: linear-gradient(120deg, #671259 0%, #ffffff 100%);
  --bl: linear-gradient(to right, #671259 0%, #ffffff 100%);
  --bt: linear-gradient(120deg, #671259 0%, #ffffff 100%);
  --p: 0px;
  --bw: 2px;
  --t: 1s;
  --td: 200ms;
  --te: ease-out;
}

.banner {
  --w: 100%;
  --h: 100%;
  max-width: var(--w);
  width: 100%;
  margin: auto;
  height: var(--h);
  background-image: var(--img);
  background-size: var(--w);
  background-repeat: no-repeat;
  background-position: center;
  transition: background-size var(--t);
  position: relative;
  box-shadow: 0 30px 30px -20px rgba(0, 0, 0, 0.45);

  .inner {
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;

    > h1 {
      position: relative;
      font-family: serif;
      color: white;
      letter-spacing: 0.4em;
      text-transform: uppercase;
      font-size: 1em;
      transition: var(--td);

      @media (min-width: 480px) {
        font-size: 2em;
      }

      @media (min-width: 768px) {
        font-size: 3em;
      }
    }
  }

  .inner::before,
  .inner::after,
  &::after,
  &::before {
    content: "";
    position: absolute;
  }

  &::before {
    top: var(--p);
    right: var(--p);
    height: 0;
    width: var(--bw);
    background-image: var(--br);
    transition: height var(--td) var(--te) 0.75s;
  }

  &::after {
    bottom: var(--p);
    right: var(--p);
    width: 0;
    height: var(--bw);
    background-image: var(--bb);
    transition: width var(--td) var(--te) 0.5s;
  }

  .inner {
    &::before {
      bottom: var(--p);
      left: var(--p);
      height: 0;
      width: var(--bw);
      background-image: var(--bl);
      transition: height var(--td) var(--te) 0.25s;
    }

    &::after {
      top: var(--p);
      left: var(--p);
      height: var(--bw);
      width: 0;
      background-image: var(--bt);
      transition: width var(--td) var(--te) 0s;
    }
  }

  &:hover {
    &::before {
      height: calc(var(--h) - var(--p) * 2);
      transition: height var(--td) var(--te) 0s;
    }

    &::after {
      width: calc(100% - var(--p) * 2);
      transition: width var(--td) var(--te) 0.25s;
    }

    .inner {
      &::before {
        height: calc(var(--h) - var(--p) * 2);
        transition: height var(--td) var(--te) 0.5s;
      }

      &::after {
        width: calc(100% - var(--p) * 2);
        transition: width var(--td) var(--te) 0.75s;
      }
    }

    @media (min-width: 768px) {
      background-size: 760px;
    }
  }
}

.more {
  display: none;
}

.news_div_top {
  min-height: 200px;
  padding: 10px;
  text-align: left;
}

.eventdes {
  width: 100%;
  height: 150px;
}

.news_div_bot {
  border: 0px;
  border-style: solid;
  border-radius: 10px;

  width: 90%;

  margin: 5px;
}

.comment_list {
  max-height: 300px;

  overflow-y: scroll;
}

.position_mid {
  margin-top: 30px;
}

.position_left {
}

.react {
  text-align: left;
}

.eventimg {
  width: 100%;
  object-fit: contain;
  max-height: 70vh;
}

.eventdivall {
  height: 100%;
  background: rgb(193, 0, 208);
  background: linear-gradient(
    355deg,
    rgba(193, 0, 208, 1) 0%,
    rgba(125, 0, 135, 1) 49%,
    rgba(59, 0, 63, 1) 100%
  );
  padding: 10px;
}

.colevent {
  word-wrap: break-word;
}

.showpara {
  display: none;
}

.morepara {
  border: 1px;
  border-radius: 20px;
  border-style: solid;
  background-color: white;
  font-weight: 500;
  margin: 10px;
}

.morepara:hover {
  border: 1px chocolate;
  border-radius: 20px;
  border-style: solid;
  background-color: white;
  font-weight: 500;

  box-shadow: 0px 0px 5px 0px rgba(189, 191, 0, 1);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(189, 191, 0, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(189, 191, 0, 1);
}

.comment_section {
  display: none;
}

.our-school-title {
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
}

.our-school-text {
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.mtext {
  font-size: var(--cus-fs-xx-large);
  text-align: left;
  font-weight: 900;
  color: goldenrod;
  text-shadow: 0px 0px 4px #ffffff;
  margin-bottom: 10px;

  @include breakpoint(medium) {
    font-size: var(--cus-fs-xxxxx-large);
    margin-bottom: 20px;
  }
}

.mtext3 {
  text-align: left;
}

.mtext2 {
  font-size: var(--cus-fs-large);

  @include breakpoint(medium) {
    font-size: var(--cus-fs-xx-large);
  }
}

@media (min-width: 1200px) {
  .keyfact {
    zoom: 0.9;
    // transform: scale(0.5);
  }
}

@media (min-width: 1000px) AND (max-width: 1200px) {
  .keyfact {
    zoom: 0.8;
    // transform: scale(0.5);
  }
}

@media (min-width: 800px) AND (max-width: 1000px) {
  .keyfact {
    zoom: 0.6;
    // transform: scale(0.5);
  }
}

@media (min-width: 600px) AND (max-width: 800px) {
  .keyfact {
    zoom: 0.5;
    // transform: scale(0.5);
  }
}

@media (min-width: 500px) AND (max-width: 600px) {
  .keyfact {
    zoom: 0.4;
    // transform: scale(0.5);
  }
}

@media (max-width: 500px) {
  .keyfact {
    zoom: 0.3;
    // transform: scale(0.5);
  }
}

// ak
.cus-key-fact {
  h1 {
    color: var(--cus-secondary);
    font-size: var(--cus-fs-normal);
    font-weight: var(--cus-fw-semibold);
  }

  .cus-fact-item {
    padding: 10px;
    border: 1px solid black;
    border-radius: 10px;
    height: 300px;
    width: 100%;

    h5 {
      color: var(--cus-primary);
      font-size: var(--cus-fs-xxsmall);
    }

    p {
      font-size: var(--cus-fs-xxxsmall);
    }
  }
}
</style>
