<template>
  <template v-if="isLoading"> <Loading /> </template>
  <template v-else>
    <div>
      <!-- for list view and grid view (tab) -->
      <!-- <ul
      class="nav nav-pills mb-3 d-flex justify-content-center p-5"
      id="pills-tab"
      role="tablist"
    >
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-list"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          List View
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-grid"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
        >
          Grid View
        </button>
      </li>
    </ul> -->

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-list"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabindex="0"
        >
          <div class="container">
            <div class="py-5">
              <!-- <h1 class="fw-bold" style="color: #daa520">News And Activity</h1> -->
              <div v-if="oldExist">
                <div class="row">
                  <div class="col-12 col-lg-8">
                    <h2 class="fw-bold mb-4">Latest Posts</h2>
                    <div
                      class="row d-flex justify-content-center"
                      v-for="event in newEvent"
                      :key="event.id"
                    >
                      <div
                        class="col-sm-7 my-2 d-flex justify-content-center w-100"
                      >
                        <div
                          class="eventdivall2 border bg-body rounded w-100 px-3"
                        >
                          <div class="events_div_top">
                            <h3>{{ event.name }}</h3>
                            <div class="d-flex justify-items-center py-1">
                              <span style="font-weight: 300; font-size: 12px;">
                                {{ formatDate(event.time) }}</span
                              >
                            </div>

                            <div
                              class="d-inline"
                              style="font-size: 20px; font-weight: 400;"
                            >
                              <span>
                                <span
                                  v-for="(word, index) in event.content"
                                  :key="index"
                                >
                                  <span v-if="index < 100">{{ word }} </span>
                                </span>
                              </span>
                              <div
                                class="collapse"
                                :id="idseemore + event.id + list"
                              >
                                <span
                                  v-for="(word, index) in event.content"
                                  :key="index"
                                >
                                  <span v-if="index >= 100">{{ word }} </span>
                                </span>
                              </div>
                            </div>

                            <button
                              class="morepara"
                              v-if="Object.keys(event.content).length > 100"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="seemore + event.id + list"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              See More...
                            </button>
                          </div>

                          <div class="news_div_bot m-3">
                            <img
                              class="eventimg"
                              :src="event.eventimg"
                              style=""
                              :alt="event.name"
                            />
                          </div>

                          <div class="react m-3">
                            <button
                              @click="like(event.id)"
                              class="position_left btn btn-warning me-4"
                            >
                              Like ({{ event.likecount }})
                            </button>
                            <button
                              class="position_left btn btn-danger me-4 comment"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="colap + event.id + list"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              Comment
                            </button>

                            <div
                              class="collapse mt-3"
                              :id="colapid + event.id + list"
                            >
                              <div class="">
                                <div class="input-group mb-3">
                                  <input
                                    :id="list + comment2 + event.id"
                                    type="text"
                                    class="form-control"
                                    placeholder="Write a comment"
                                    aria-label="Your comment"
                                    aria-describedby="button-addon2"
                                  />
                                  <button
                                    @click="comment1(event.id, list)"
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    id="button-addon2"
                                  >
                                    Send
                                  </button>
                                </div>
                                <div class="comment_list">
                                  <div
                                    v-for="comment in commentList"
                                    :key="comment.id"
                                  >
                                    <div
                                      class="border rounded p-3 mb-4"
                                      v-if="event.id == comment.eventID"
                                    >
                                      <div>
                                        <div class="row">
                                          <div
                                            class="col d-flex align-items-center gap-2"
                                          >
                                            <h5>Anonymous</h5>
                                            <div
                                              style="width: 5px; height: 100%; border-right: 1px; border-right-style: solid;"
                                            ></div>
                                            <span
                                              style="font-weight: 300; font-size: 12px;"
                                              class="ms-1"
                                              >{{ formatDate(comment.time) }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col">
                                            <span
                                              >{{ comment.commentcontent }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <h2 class="mb-4">Older Posts</h2>
                    <template v-if="oldEvent.length > 0">
                      <div
                        v-for="(event, index) in oldEvent"
                        :key="index"
                        class="card border my-2"
                      >
                        <img
                          :src="event.eventimg"
                          class="card-img-top"
                          alt="event.name"
                        />
                        <div class="card-body">
                          <h5 class="card-title text-left">{{ event.name }}</h5>

                          <div class="d-inline">
                            <span>
                              <span
                                v-for="(dat_2, datint) in event"
                                :key="datint"
                              >
                                <span v-if="datint < 100">{{ dat_2 }} </span>
                              </span>
                            </span>
                            <div
                              class="collapse"
                              :id="idseemore + event.id + list"
                            >
                              <span
                                v-for="(dat_2, datint) in event.content"
                                :key="datint"
                              >
                                <span v-if="datint >= 100">{{ dat_2 }} </span>
                              </span>
                            </div>
                          </div>
                          <button
                            class="morepara"
                            v-if="Object.keys(event.content).length > 100"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="seemore + event.id + list"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            See More...
                          </button>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="card border py-4 my-2">No posts to show.</div>
                    </template>
                  </div>
                </div>
              </div>

              <div v-if="!oldExist">
                <div class="row px-5">
                  <div class="col-2"></div>
                  <div class="col-8">
                    <h2 class="fw-bold mb-4">Latest Posts</h2>
                    <div
                      class="row gx-0 d-flex justify-content-center"
                      v-for="event in eventListTwo"
                      :key="event.id"
                    >
                      <div
                        class="col-sm-7 my-5 d-flex justify-content-center w-100"
                      >
                        <div
                          class="eventdivall2 border bg-body rounded w-100 px-3"
                        >
                          <div class="events_div_top">
                            <h3>{{ event.name }}</h3>
                            <div class="d-flex justify-items-center py-1">
                              <span style="font-weight: 300; font-size: 12px;">
                                {{ formatDate(event.time) }}</span
                              >
                            </div>

                            <div
                              class="d-inline"
                              style="font-size: 20px; font-weight: 400;"
                            >
                              <span>
                                <span
                                  v-for="(dat_2, index) in event.content"
                                  :key="index"
                                >
                                  <span v-if="index < 100">{{ dat_2 }} </span>
                                </span>
                              </span>
                              <div
                                class="collapse"
                                :id="idseemore + event.id + list"
                              >
                                <span
                                  v-for="(dat_2, index) in event.content"
                                  :key="index"
                                >
                                  <span v-if="index >= 100">{{ dat_2 }} </span>
                                </span>
                              </div>
                            </div>

                            <br />

                            <button
                              class="morepara"
                              v-if="Object.keys(event.content).length > 100"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="seemore + event.id + list"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              See More...
                            </button>
                          </div>

                          <div class="news_div_bot m-3">
                            <img
                              class="eventimg"
                              :src="event.eventimg"
                              style=""
                              alt=""
                            />
                          </div>

                          <div class="react m-3">
                            <button
                              @click="like(event.id)"
                              class="position_left btn btn-warning me-4"
                            >
                              Like ({{ event.likecount }})
                            </button>
                            <button
                              class="position_left btn btn-danger me-4 comment"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="colap + event.id + list"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              comment
                            </button>

                            <div
                              class="collapse mt-3"
                              :id="colapid + event.id + list"
                            >
                              <div class="">
                                <div class="input-group mb-3">
                                  <input
                                    :id="list + comment2 + event.id"
                                    type="text"
                                    class="form-control"
                                    placeholder="Write a comment"
                                    aria-label="Your comment"
                                    aria-describedby="button-addon2"
                                  />
                                  <button
                                    @click="comment1(event.id, list)"
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    id="button-addon2"
                                  >
                                    Send
                                  </button>
                                </div>
                                <div class="comment_list">
                                  <div
                                    v-for="comment in commentList"
                                    :key="comment.id"
                                  >
                                    <div
                                      class="border rounded p-3 mb-4"
                                      v-if="event.id == comment.eventID"
                                    >
                                      <div>
                                        <div class="row">
                                          <div
                                            class="col d-flex align-items-center gap-2"
                                          >
                                            <h5>Anonymous</h5>
                                            <div
                                              style="width: 5px; height: 100%; border-right: 1px; border-right-style: solid;"
                                            ></div>
                                            <span
                                              style="font-weight: 300; font-size: 12px;"
                                              class="ms-1"
                                              >{{ formatDate(comment.time) }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col">
                                            <span
                                              >{{ comment.commentcontent }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="pills-grid"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabindex="0"
        >
          <div class="">
            <div class="">
              <div class="row gx-0 d-flex justify-content-center text-white">
                <div
                  class="col-sm-3 m-2 colevent"
                  v-for="(dat2, index) in eventListTwo"
                  :key="index"
                >
                  <div class="eventdivall border bg-body rounded">
                    <div class="events_div_top">
                      <span style="text-align: right; display: block">
                        {{ dat2.time }}</span
                      >
                      <h3>{{ dat2.name }}</h3>

                      <div class="d-inline">
                        <span>
                          <span
                            v-for="(dat_2, datint) in dat2.content"
                            :key="datint"
                          >
                            <span v-if="datint < 100"> {{ dat_2 }} </span>
                            <span
                              style="color: cornflowerblue"
                              v-if="datint >= 100 && datint < 101"
                              ><br />
                              More
                            </span>
                            <span v-if="datint >= 100 && datint < 104"
                              >...</span
                            >
                          </span>
                        </span>
                        <div class=" " :id="idseemore + dat2.id">
                          <span
                            v-for="(dat_2, datint) in dat2.content"
                            :key="datint"
                          >
                          </span>
                        </div>
                      </div>

                      <br />

                      <div class="showpara"></div>
                    </div>

                    <div class="react m-3">
                      <button
                        @click="like(dat2.id)"
                        class="position_left btn btn-warning me-4"
                      >
                        Like ({{ dat2.likecount }})
                      </button>
                      <button
                        class="position_left btn btn-danger me-4 comment"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="colap + dat2.id + grid"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        cmt
                      </button>

                      <a
                        class="position_left btn btn-info"
                        data-bs-toggle="modal"
                        :data-bs-target="moredetail + dat2.id"
                        >More
                      </a>

                      <div class="collapse mt-3" :id="colapid + dat2.id + grid">
                        <div class="">
                          <div class="input-group mb-3">
                            <input
                              :id="grid + comment2 + dat2.id"
                              type="text"
                              class="form-control"
                              placeholder="Write a comment"
                              aria-label="Your comment"
                              aria-describedby="button-addon2"
                            />
                            <button
                              @click="comment1(dat2.id, grid)"
                              class="btn btn-outline-secondary"
                              type="button"
                              id="button-addon2"
                            >
                              send
                            </button>
                          </div>
                          <div class="comment_list">
                            <div
                              v-for="(dat3, index) in commentList"
                              :key="index"
                            >
                              <div
                                class="border rounded p-3 mb-4"
                                v-if="dat2.id == dat3.eventID"
                              >
                                <div>
                                  <div class="row">
                                    <div class="col">
                                      <h3>Anonymous</h3>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col">
                                      <span>{{ dat3.commentcontent }} </span>
                                    </div>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col d-flex justify-content-end">
                                      <span>{{ dat3.time }} </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div v-for="(dat2, index) in eventListTwo" :key="index">
        <div
          class="modal fade"
          :id="idmoredetail + dat2.id"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel"></h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="">
                  <div class="">
                    <div class="row gx-0 d-flex justify-content-center">
                      <div
                        class="col-sm-10 colevent"
                        :id="idlistpost + dat2.id"
                      >
                        <div class="eventdivall rounded">
                          <div class="events_div_top">
                            <span style="text-align: right; display: block">
                              {{ dat2.time }}</span
                            >
                            <h3>{{ dat2.name }}</h3>

                            <div class="d-inline">
                              <span>
                                <span
                                  v-for="(dat_2, datint) in dat2.content"
                                  :key="datint"
                                >
                                  <span v-if="datint < 100">{{ dat_2 }} </span>
                                </span>
                              </span>
                              <div class="collapse" :id="idseemore + dat2.id">
                                <span
                                  v-for="(dat_2, datint) in dat2.content"
                                  :key="datint"
                                >
                                  <span v-if="datint >= 100">{{ dat_2 }} </span>
                                </span>
                              </div>
                            </div>

                            <br />

                            <button
                              class="morepara"
                              v-if="Object.keys(dat2.content).length > 100"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="seemore + dat2.id"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              See More...
                            </button>
                          </div>

                          <div class="news_div_bot m-3">
                            <img
                              class="eventimg"
                              :src="dat2.eventimg"
                              style=""
                              alt=""
                            />
                          </div>

                          <div class="react m-3">
                            <button
                              @click="like(dat2.id)"
                              class="position_left btn btn-warning me-4"
                            >
                              Like ({{ dat2.likecount }})
                            </button>
                            <button
                              class="position_left btn btn-danger me-4 comment"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="colap + dat2.id + model"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                            >
                              comment
                            </button>

                            <div
                              class="collapse mt-3"
                              :id="colapid + dat2.id + model"
                            >
                              <div class="">
                                <div class="input-group mb-3">
                                  <input
                                    :id="model + comment2 + dat2.id"
                                    type="text"
                                    class="form-control"
                                    placeholder="Write a comment"
                                    aria-label="Your comment"
                                    aria-describedby="button-addon2"
                                  />
                                  <button
                                    @click="comment1(dat2.id, model)"
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    id="button-addon2"
                                  >
                                    send
                                  </button>
                                </div>
                                <div class="comment_list">
                                  <div
                                    v-for="(dat3, index) in commentList"
                                    :key="index"
                                  >
                                    <div
                                      class="border rounded p-3 mb-4"
                                      v-if="dat2.id == dat3.eventID"
                                    >
                                      <div>
                                        <div class="row">
                                          <div class="col">
                                            <h3>Anonymous</h3>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col">
                                            <span
                                              >{{ dat3.commentcontent }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="row mt-1">
                                          <div
                                            class="col d-flex justify-content-end"
                                          >
                                            <span>{{ dat3.time }} </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import axios from "axios";
import Loading from "../components/general/Loading.vue"
import $ from "jquery";

export default {
  components: {
    Loading
  },
  data() {
    return {
      link: "https://backend.smkedugroup.com",
      
      eventListTwo: null,
      commentList: null,
      oldExist: null,
      commentcontent: "",
      comment2: "comment",
      colap: "#colap",
      colapid: "colap",
      seemore: "#seemore",
      idseemore: "seemore",
      moredetail: "#post",
      idmoredetail: "post",
      model: "model",
      list: "list",
      grid: "grid",
      newEvent: [],
      oldEvent: [],
      isLoading: true,
    };
  },
  methods: {
    comment1(id, type) {
      let article = {
        eventID: id,
        commentcontent: $("#" + type + "comment" + id).val(),
      };

      axios
        .post(this.link + "/api/comment", article)
        .then((response) => {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success",
            customClass: "sweetalertsuccess",
            showConfirmButton: false,
            timer: 1500,
          });
          $("#" + type + "comment" + id).val("");
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          this.show();
          this.showcmt();
        });
    },

    like(id) {
      const islike = localStorage.getItem("isLike" + id);
      if (islike == "true=" + id) {
        alert("you already liked it");
      } else {
        axios
          .post(this.link + "/api/like/" + id)
          .then((response) => console.log(response))
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          }).finally(() => this.show());

        localStorage.setItem("isLike" + id, "true=" + id);
      }
    },
    show() {
      axios
        .get(this.link + "/api/eventinput")
        .then((response) => {
          this.eventListTwo = response.data;

          if (this.eventListTwo.length > 4) {
            this.oldEvent = this.eventListTwo
              .slice(0, this.eventListTwo.length - 4)
              .reverse();
            this.newEvent = this.eventListTwo
              .slice(this.eventListTwo.length - 4, this.eventListTwo.length)
              .reverse();
            console.log(this.eventListTwo, this.newEvent, this.oldEvent);
          } else {
            this.newEvent = this.eventListTwo;
          }

          this.checkOldEvent();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showcmt() {
      axios
        .get(this.link + "/api/comment")
        .then((response) => {
          this.commentList = response.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    checkOldEvent() {
      if (this.eventListTwo.length > 2) {
        this.oldExist = true;
      } else {
        this.oldExist = false;
      }
    },
    formatDate(date) {
      const dateobj = new Date(date);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric"
      };
      if (dateobj == 'Invalid Date') {
        return date;
      }
      return dateobj.toLocaleDateString("en-US", options);
    }
  },
  async mounted() {
    this.show();

    this.showcmt();
  },
  updated() {
    $(".comment").click(function () {
      //$('.hideme').hide();
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");

        $(".comment_section").slideUp("slow");
      } else {
        $(".comment_section").slideUp("slow");
        $(".comment").removeClass("active");
        $(this).addClass("active");

        $(this).next().filter(".comment_section").slideDown("slow");
      }
    });

    $(".morepara").click(function (event) {
      //$('.hideme').hide();
      if ($(this).hasClass("active")) {
        event.target.classList.remove("active");

        $(".showpara").slideUp("slow");
        $(this).text("See More...");
      } else {
        $(".showpara").slideUp("slow");
        $(".morepara").removeClass("active");
        $(".morepara").text("See More...");

        event.target.classList.add("active");
        $(this).prev().filter(".showpara").slideDown("slow");
        $(this).text("See Less");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.more {
  display: none;
}
.events_div_top {
  padding: 10px;
  text-align: left;
}
.eventdes {
  width: 100%;
  height: 150px;
}
.news_div_bot {
  border: 0px;
  border-style: solid;
  border-radius: 10px;

  width: 100%;

  margin: 5px;
}
.comment_list {
  max-height: 300px;

  overflow-y: scroll;
}
.position_mid {
  margin-top: 30px;
}
.position_left {
}
.react {
  text-align: left;
}
.eventimg {
  width: 100%;
  object-fit: contain;
  max-height: 70vh;
}
.colevent {
  word-wrap: break-word;
  min-width: 400px;
}
.showpara {
  display: none;
}
.morepara {
  border: 0px;
  font-weight: 200;
  color: rgba($color: #111111, $alpha: 0.7);
}
.morepara:hover {
  font-weight: 500;
  color: rgba($color: #000000, $alpha: 0.9);
}
.comment_section {
  display: none;
}
</style>
