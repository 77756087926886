<template>
  <div>
    <nav class="p-3">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          class="nav-link active"
          id="nav-form-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-form"
          type="button"
          role="tab"
          aria-controls="nav-form-tab"
          aria-selected="true"
        >
          Appointment Enquiry
        </button>
        <button
          class="nav-link"
          id="nav-list-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-tracking"
          type="button"
          role="tab"
          aria-controls="nav-list-tab"
          aria-selected="false"
        >
          Appointment Tracking
        </button>
      </div>
    </nav>

    <div class="container">
      <div class="row d-flex justify-content-center mb-5">
        <main class="col">
          <!-- <div class="page-header">
            <h1 style="color:goldenrod; text-transform: uppercase;">
              <b>Appointment Enquiry</b>
            </h1>
          </div> -->

          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-form"
              role="tabpanel"
              aria-labelledby="nav-form-tab"
              tabindex="0"
            >
              <AppointmentForm />
            </div>

            <div
              class="tab-pane fade"
              id="nav-tracking"
              role="tabpanel"
              aria-labelledby="nav-list-tab"
              tabindex="0"
            >
              <AppointmentTrackList />
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import AppointmentForm from "./AppointmentForm.vue";
import AppointmentTrackList from './AppointmentTrackList.vue';

export default {
  components: {
    AppointmentForm,
    AppointmentTrackList
  },
}
</script>

<style lang="scss" scoped></style>
