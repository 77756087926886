<template>
  <div v-if="credentials?.length > 0" class="mt-5 text-center">
    <h5 class="teacher-logo-title">
      {{ activeCredential.name }}
    </h5>
    <div class="d-flex justify-content-center align-items-center gap-2 flex-wrap">
      <div
        v-for="credential in credentials"
        style="cursor: pointer;"
        @click="activeCredential = credential"
        :key="credential.id"
      >
        <img
          :class="`teacher-logo ${credential.id == activeCredential.id ? 'teacher-logo-active' : ''}`"
          :src="credential.photo_path"
          :alt="credential.name"
          unselectable="on"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    credentials: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      activeCredential: this.credentials[0],
    };
  },
}
</script>

<style>
.teacher-logo {
  width: 150px;
  height: 150px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: 70%;
}

.teacher-logo-active {
  opacity: 100%;
}
</style>
