<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <template v-else>
    <div style="background-color: rgb(244, 234, 223)" class="p-3">
      <div
        class="modal fade"
        id="createmodal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title"
                style="text-align: center"
                id="staticBackdropLabel"
              ></h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row d-flex justify-content-center">
                <div class="ms-3">
                  <h1 class="m-3">Event Register</h1>
                  <div class=" ">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >Name</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="text"
                              class="form-control"
                              v-model="name"
                              id="name"
                              required
                            />
                          </div>
                        </div>

                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >Content
                          </label>
                          <div class="col-sm-8 ms-3">
                            <textarea
                              name=""
                              class="form-control"
                              v-model="content"
                              id="content"
                              required
                              cols="30"
                              rows="10"
                              style="resize: none"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >Image</label
                          >
                          <div class="col-sm-8 ms-3">
                            <img
                              :src="photobase64"
                              v-if="photobase64"
                              for="inputGroupSelect01"
                              class="img-fluid rounded"
                              id="teacherimgphoto"
                              style="width: 400px; height: 250px"
                            />
                          </div>
                        </div>
                        <div class="mb-3 row">
                          <div class="col-sm-2"></div>
                          <div class="col-sm-8">
                            <input
                              type="file"
                              @change="handleImage"
                              id="eventimg"
                              class="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-destory"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                class="d-flex justify-align-center btn btn-primary"
                @click="store"
              >
                <span
                  id="createbtnspinner"
                  class="spinner-border"
                  role="status"
                  aria-hidden="true"
                  v-if="isSaveBtnLoading"
                ></span>
                <span id="createbtntext" v-if="! isSaveBtnLoading">
                  Save Record</span
                >
              </button>

              <button
                id="createclose"
                style="visibility: hidden"
                type="button"
                data-bs-dismiss="modal"
              ></button>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <h1 class="fw-bold">Event List</h1>
        <div class="d-flex justify-content-end mt-4">
          <button
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#createmodal"
          >
            Create Event
          </button>
        </div>

        <div class="card mt-2">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 ms-1">
                <div class="row d-flex justify-content-end mt-5">
                  <div class="col-sm-5 ms-3">
                    <div class="mb-3 row">
                      <label for="inputPassword" class="col-sm-4 col-form-label"
                        >Search by</label
                      >
                      <div class="col-sm-6 ms-1">
                        <input
                          type="text"
                          class="form-control"
                          v-model="search"
                          @keyup="searchdata()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-5 overflow-scroll">
              <div
                class="container"
                style="max-width: 100%; max-height: 1000px;"
              >
                <table class="table table-bordered table-hover">
                  <thead
                    style="position: sticky; top: 0; background-color: white"
                  >
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Content</th>
                      <th>Photo</th>
                      <th>Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    <template v-if="isTableLoading">
                      <tr>
                        <td colspan="6">
                          <Loading />
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <template v-if="eventList.length > 0">
                        <tr v-for="(data, index) in eventList" :key="data.id">
                          <td>{{ index + 1 }}</td>
                          <td>{{ data.name }}</td>
                          <td style="text-align: left">{{ data.content }}</td>

                          <td>
                            <img
                              :src="data.eventimg"
                              :alt="data.name"
                              id="teacherimgphoto"
                              style="max-width: 200px; object-fit: contain; height: 100px"
                            />
                          </td>
                          <td>{{ data.time }}</td>

                          <td>
                            <button
                              v-on:click="editmodel(data.id)"
                              class="btn btn-warning mb-2 me-3"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                            >
                              Edit
                            </button>

                            <button
                              v-on:click="deleteRecord(data.id)"
                              class="btn btn-danger"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td colspan="6">No event record exist!</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <form v-for="data in eventList" :key="data.id">
          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel"></h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="">
                    <h1 class="m-3">Course Register</h1>
                    <div class="mt-5">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="mb-3 row">
                            <label
                              for="inputPassword"
                              class="col-sm-2 col-form-label"
                              >Name</label
                            >
                            <div class="col-sm-8 ms-3">
                              <input
                                type="text"
                                class="form-control"
                                v-model="uname"
                                id="name"
                                required
                              />
                            </div>
                          </div>

                          <div class="mb-3 row">
                            <label
                              for="inputPassword"
                              class="col-sm-2 col-form-label"
                              >Content
                            </label>
                            <div class="col-sm-8 ms-3">
                              <textarea
                                name=""
                                class="form-control"
                                v-model="ucontent"
                                id="studied"
                                required
                                cols="30"
                                rows="10"
                                style="resize: none"
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="mb-3 row">
                            <label
                              for="inputPassword"
                              class="col-sm-2 col-form-label"
                              >Image</label
                            >
                            <div class="col-sm-8 ms-3">
                              <!-- <img src="./../assets/img/default.png" v-if="photobase64==''" for="inputGroupSelect01" class="img-fluid rounded" id="teacherimgphoto" style="width:250px; height: 350px;"> -->

                              <img
                                :src="uphotobase64"
                                v-if="uphotobase64"
                                for="inputGroupSelect01"
                                class="img-fluid rounded"
                                id="teacherimgphoto"
                                style="width: 400px; height: 250px"
                              />
                            </div>
                          </div>
                          <div class="mb-3 row">
                            <div class="col-sm-2"></div>
                            <div class="col-sm-8">
                              <input
                                type="file"
                                @change="uhandleImage"
                                id="uteacherimg"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-destory"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    v-on:click="update(edid)"
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-warning"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import { DeviceUUID } from "device-uuid";
import Loading from '@/components/general/Loading.vue';

export default {
  components: {
    Loading
  },
  data() {
    return {
      
      link: "https://backend.smkedugroup.com",
      isLoading: true,
      isTableLoading: true,
      isSaveBtnLoading: false,
      sort: "id",

      name: "",
      content: "",
      photobase64: `

             `,

      uname: "",
      ucontent: "",

      uphotobase64: "",

      edid: "",

      articleId: "",
      eventList: [],
      search: "",
    };
  },

  methods: {
    searchdata() {
      if (this.search == "") {
        this.show();
        return;
      }

      this.isTableLoading = true;

      let article = {
        search: this.search.toLowerCase(),
      };
      axios
        .post(this.link + "/api/searchevent", article)
        .then((response) => {
          this.eventList = response.data;
          this.isTableLoading = false;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.isTableLoading = false;
        });
    },

    handleImage(e) {
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },

    uhandleImage(e) {
      const selectedImage = e.target.files[0];
      this.ucreateBase64Image(selectedImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.photobase64 = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    ucreateBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.uphotobase64 = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    show() {
      this.isTableLoading = true;

      axios
        .get(this.link + "/api/eventinput")
        .then((response) => {
          console.log(response);
          this.eventList = response.data;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => this.isTableLoading = false);
    },

    store() {
      if (this.name == "") {
        return document.getElementById("name").focus();
      } else if (this.content == "") {
        return document.getElementById("content").focus();
      }
      this.isSaveBtnLoading = true;

      let article = {
        name: this.name,
        content: this.content,
        eventimg: this.photobase64,
      };
      axios
        .post(this.link + "/api/eventinput", article)
        .then((response) => {
          this.show();

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success",
            customClass: "sweetalertsuccess",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error',
            confirmButtonText: 'OK',
          });
        }).finally(() => {
          this.show();
          this.isSaveBtnLoading = false;
          $("#createclose").click();
        });
    },

    editmodel(id) {
      this.edid = id;
      for (let a = 0; a < this.eventList.length; a++) {
        if (this.eventList[a].id == this.edid) {
          this.uname = this.eventList[a].name;
          this.ucontent = this.eventList[a].content;
          this.uphotobase64 = this.eventList[a].eventimg;
        }
      }
    },

    update(id) {
      //     alert(id)
      let article = {
        name: this.uname,
        content: this.ucontent,
        eventimg: this.uphotobase64,
      };

      this.isSaveBtnLoading = true;
      axios
        .post(this.link + "/api/updateEvent/" + id, article)
        .then((response) => {
          this.show();

          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success",
            customClass: "sweetalertsuccess",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error',
            confirmButtonText: 'OK',
          });
        })
        .finally(() => this.isSaveBtnLoading = false);
    },

    deleteRecord(id) {
      let result = confirm("Want to delete?");
      if (result) {
        axios
          .post(this.link + "/api/deleteEvent/" + id)
          .then((response) => {
            console.log(response);
            this.show();

            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Deleted',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
      }
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      this.show();
    } catch (error) {
      console.log("error mounting event page: ", error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
