<template>
  <template v-if="isLoading">
    <div>
      <Loading />
    </div>
  </template>
  <template v-else>
    <div class="container">
      <div class="d-flex justify-content-start mt-3">
        <button
          id="backButton"
          class="btn btn-primary btn-lg shadow"
          @click="goBack"
        >
          <i class="bi bi-arrow-left-circle"></i> Go Back
        </button>
      </div>
      <div class="row my-5">
        <div class="col-12">
          <div class="d-flex justify-content-between mb-3">
            <h3 class="fw-bold">Teacher Create</h3>
          </div>
          <div class="card p-3 pb-0">
            <form action="">
              <div class="row mb-3">
                <div class="col-sm-6 ">
                  <div class="mb-3 row">
                    <label for="name" class="col-sm-2 col-form-label"
                      >Name</label
                    >
                    <div class="col-sm-8 ms-3">
                      <input
                        type="text"
                        class="form-control"
                        v-model="name"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3 row">
                    <label for="position" class="col-sm-2 col-form-label "
                      >Position</label
                    >
                    <div class="col-sm-8 ms-3">
                      <input
                        type="text"
                        class="form-control"
                        v-model="position"
                        id="position"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-sm-6">
                  <div class="mb-3 row">
                    <label for="studied" class="col-sm-2 col-form-label"
                      >Studied
                    </label>
                    <div class="col-sm-8 ms-3">
                      <textarea
                        name=""
                        class="form-control"
                        v-model="studied"
                        id="studied"
                        required
                        cols="30"
                        rows="5"
                        style="resize: none;"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3 row">
                    <label for="message" class="col-sm-2 col-form-label"
                      >Message</label
                    >
                    <div class="col-sm-8 ms-3">
                      <textarea
                        name=""
                        class="form-control"
                        v-model="message"
                        required
                        id="message"
                        cols="30"
                        rows="5"
                        style="resize: none;"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-sm-6">
                  <div class="mb-3 row">
                    <label for="credential" class="col-sm-2 col-form-label "
                      >Credential</label
                    >
                    <div class="col-sm-8 ms-3">
                      <input
                        type="text"
                        class="form-control"
                        v-model="src.name"
                        id="credential"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3 row">
                    <label for="categoryId" class="col-sm-2 col-form-label"
                      >Category</label
                    >
                    <div class="col-sm-8 ms-3">
                      <select
                        id="categoryId"
                        class="form-select"
                        v-model="categoryId"
                        aria-label=""
                        required
                      >
                        <option
                          v-for="category in categoryList"
                          :value="category.id"
                          :key="category.id"
                        >
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-6">
                  <div class="mb-3">
                    <div class="col-sm-8">
                      <h4>Credential Photo</h4>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="mb-3 row">
                      <div class="col-sm-8">
                        <img
                          :src="src.photobase64"
                          v-if="src.photobase64"
                          class="img-fluid rounded"
                          id="credential-new-showed-photo"
                          style="width: 250px; height: 350px;"
                        />
                      </div>
                    </div>
                    <div class="mb-3 row">
                      <div class="col-sm-8">
                        <input
                          type="file"
                          @change="handleListPhotoChange"
                          id="credential-new-photo"
                          class="form-control"
                        />
                      </div>
                      <div class="col-sm-4 d-flex justify-content-end">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="addCredential"
                        >
                          Add new credential
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="mb-3 row">
                    <label for="teacherimgphoto" class="col-sm-2 col-form-label"
                      >Teacher Image</label
                    >
                    <div class="col-sm-8 ms-3">
                      <img
                        :src="photobase64"
                        v-if="photobase64"
                        for="inputGroupSelect01"
                        class="img-fluid rounded"
                        id="teacherimgphoto"
                        style="width:250px; height: 350px;"
                      />
                    </div>
                  </div>
                  <div class="mb-3 row">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-8 ">
                      <input
                        type="file"
                        @change="handleImage"
                        id="teacherimg"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-12">
                  <template v-if="credentials.length > 0">
                    <h4 class="text-start mb-3">Added Credential List</h4>
                    <div class="row">
                      <div
                        v-for="(each, photoIndex) in credentials"
                        :key="photoIndex"
                        class="col-md-4 col-sm-5 border p-3 mb-3 rounded shadow-sm card"
                      >
                        <div
                          class="mb-3 d-flex align-items-start justify-content-between"
                        >
                          <div class="flex flex-col w-100 justify-content-center align-items-center">
                            <img
                              :src="each.photobase64"
                              class="img-fluid rounded"
                              id="credential-new-showed-photo"
                              style="width:350px; height: 350px;"
                            />
                            <input type="text" class="form-control me-3"
                                v-model="each.name">
                          </div>
                          <button
                            type="button"
                            class="btn btn-danger"
                            @click="removeCredential(photoIndex)"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="mt-5">No Credential Yet!</div>
                  </template>
                </div>
              </div>

              <div class="d-flex justify-content-end mb-3">
                <button
                  type="button"
                  class="btn btn-primary"
                  :disabled="isSubmitting"
                  @click="createTeacher"
                >
                  {{ isSubmitting
                  ? 'Creating...' : 'Create' }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import axios from "axios";
import { defaultTeacherPhotoBase64 } from "@/constants/index"

export default {
  data() {
    return {
      link: "https://backend.smkedugroup.com",
      credentials: [],
      src: {
        id: null,
        name: '',
        photobase64: defaultTeacherPhotoBase64,
      },

      imgFile: '',
      name:'',
      studied:'',
      position:'',
      credential:'',
      message:'',
      categoryId: '',
      photobase64: defaultTeacherPhotoBase64,
      isLoading: true,
      isSubmitting: false,
      categoryList: [],
    }
  },

  methods: {
    handleImage(e) {
      this.imgFile = e.target.files[0];
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.photobase64=e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    handleListPhotoChange(e) {
      const selectedImage = e.target.files[0];
      this.createBase64ImageForList(selectedImage);
    },

    createBase64ImageForList(fileObject) {
      const reader = new FileReader();
      reader.onload = (e)  => {
        this.src.photobase64 = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },

    addCredential() {
      let data = {
        name: this.src.name,
        photobase64: this.src.photobase64 ?? '',
      };

      this.credentials.push(data);
      this.src.name = '';
      this.src.photobase64 = defaultTeacherPhotoBase64;
    },

    removeCredential(credentialPhotosIndex) {
      this.credentials.splice(credentialPhotosIndex, 1)
    },

    getCategories() {
      axios.get(this.link + "/api/teacher-category-list")
        .then(response => {
          this.categoryList = response.data;
        })
        .catch(error => {
          console.error("There was an error!", error);
        });
    },

    async createTeacher() {
      this.isSubmitting = true

      if (this.name == "") {
        return document.getElementById("name").focus();
      }
      if (this.studied == "") {
        return document.getElementById("studied").focus();
      }
      if (this.position == "") {
        return document.getElementById("position").focus();
      }
      if (this.message == "") {
        return document.getElementById("message").focus();
      }

      let article = new FormData()
      article.append('name', this.name);
      article.append('studied', this.studied);
      article.append('position', this.position);
      article.append('message', this.message);
      article.append('teacher_photo', this.imgFile);
      article.append('teacher_category_id', this.categoryId);
      article.append('isdisplay', 1);

      let credentialArray = [];
      this.credentials.map(credential => {
        credentialArray.push({
          name: credential.name,
          photo: credential.photobase64,
        });
      });
      article.append("credentials", JSON.stringify(credentialArray));

      axios.post(this.link + "/api/teacher", article, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            customClass:'sweetalertsuccess',
            showConfirmButton: false,
            timer: 1500
          });

          clearTimeout(3000);
          setTimeout(() => {
            this.$router.push({ name: 'TeacherRegister' })
          }, 3000);
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    goBack() {
      window.location.assign("/admin/teacher-register");
    },
  },

  async mounted() {
    this.isLoading = true

    try {
      this.getCategories();
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
