<template>
  <div style="background-color:rgb(244, 234, 223)" class="p-3">
    <div class="container">
      <h1 class="fw-bold">Teacher List</h1>
      <div class="d-flex justify-content-end mt-4 gap-3">
        <template v-if="sortMode">
          <button
            class="btn btn-destory"
            @click="sortMode = false"
            :disabled="isSortingSaveBtnLoading"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary"
            @click="saveSorting"
            :disabled="isSortingSaveBtnLoading"
          >
            <span
              v-if="isSortingSaveBtnLoading"
              id="sortingsavebtnspinner"
              class="spinner-border "
              role="status"
              aria-hidden="true"
            ></span>
            <span v-else>Save</span>
          </button>
        </template>
        <template v-if="!sortMode">
          <button
            v-if="teacherList?.length > 0"
            class="btn btn-primary"
            @click="enableSortMode"
          >
            Sort List
          </button>
          <router-link
            v-if="!sortMode"
            :to="{ name: `TeacherRegisterCreate` }"
            class="btn btn-primary"
          >
            Create Teacher
          </router-link>
        </template>
      </div>

      <SortList
        v-if="sortMode"
        :list="teacherList"
        @onChange="handleSortListChange"
      />

      <div v-else class="card mt-2">
        <div class="card-body">
          <div class="row ">
            <div class="col-sm-12 ms-1">
              <div class="row d-flex justify-content-end mt-5 ">
                <div class="col-sm-5 ms-3">
                  <div class="mb-3 row">
                    <label for="inputPassword" class="col-sm-4 col-form-label"
                      >Search by</label
                    >
                    <div class="col-sm-6 ms-1">
                      <input
                        type="text"
                        class="form-control"
                        v-model="search"
                        @keyup="searchData()"
                        id="color"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center mt-5">
                <div
                  class="container"
                  style="max-width: 100%; max-height: 1000px; overflow: scroll"
                >
                  <table class="table table-bordered table-hover">
                    <thead
                      style="position: sticky;top: 0;background-color: white;"
                    >
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Studied</th>
                        <th>Position</th>
                        <th>Message</th>
                        <th>Photo</th>
                        <th>Is Display</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <template v-if="isTableLoading">
                        <tr>
                          <td colspan="8">
                            <Loading />
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <template v-if="teacherList.length > 0">
                          <tr
                            v-for="(teacher,index) in teacherList"
                            :key="teacher.id"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ teacher.name }}</td>
                            <td>{{ teacher.studied }}</td>
                            <td style="text-align:left;">
                              {{ teacher.position }}
                            </td>
                            <td style="text-align:left;">
                              {{ teacher.message }}
                            </td>
                            <td>
                              <img
                                :src="link + '/schooldata/teacherphoto/' + teacher.teacher_photo_path"
                                alt=""
                                id="teacherimgphoto"
                                style="width:60px; height: 100px;"
                              />
                            </td>
                            <td class="d-flex justify-content-center">
                              <div class="form-check form-switch">
                                <input
                                  v-if="teacher.isDisplay=='1'"
                                  class="form-check-input"
                                  type="checkbox"
                                  :id="isDisplay+teacher.id"
                                  checked
                                  @change="display(teacher.id)"
                                />

                                <input
                                  v-if="teacher.isDisplay=='0'"
                                  :id="isDisplay+teacher.id"
                                  class="form-check-input"
                                  type="checkbox"
                                  @change="display(teacher.id)"
                                />
                              </div>
                            </td>

                            <td>
                              <router-link
                                :to="`/admin/teacher-register/${teacher.id}/edit`"
                                class="btn btn-warning mb-3 me-3"
                                >Edit</router-link
                              >
                              <button
                                v-on:click="deleteTeacher(teacher.id)"
                                class="btn btn-danger"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        </template>
                        <tr v-else>
                          <td colspan="8">No teacher record exist!</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import Loading from '@/components/general/Loading.vue';
import SortList from './SortList.vue';
import { getAxiosClient } from '@/data/axios';

export default {
  components:{
    Loading,
    SortList,
  },

  data(){
    return{
      link: "https://backend.smkedugroup.com",
      isLoading: true,
      isTableLoading: true,
      sortMode: false,
      isSortingSaveBtnLoading: false,
      sort:'id',
      edid:'',
      isDisplay:'isDisplay',
      articleId:'',
      teacherList:null,
      search:'',
      sortedList: [],
      payload: {
        class_type: 'weekday classes',
        year_level: '',
        intake: '',
        from: '',
        to: '',
        order: 1,
        curriculum: [],
      },
      src: {
        currentList: ''
      },
    };
  },

  methods: {
    searchData() {
      if(this.search == "") {
        this.show();
        return;
      }

      this.isTableLoading = true;

      let article = {
        search:this.search.toLowerCase()
      };

      axios.post(this.link+"/api/searchteacher", article)
        .then(response =>{
          this.teacherList = response.data;
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => this.isTableLoading = false);
    },

    show() {
      this.isTableLoading = true;
      axios.get(this.link+"/api/teacher")
        .then(response => {
          this.teacherList = response.data;
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => this.isTableLoading = false);
    },

    display(id) {
      let istrue='0';
      if($('#isDisplay'+id).is(':checked')) {
        istrue=1;
      } else {
        istrue=0;
      }
      let article = {
        isDisplay: istrue,
      };

      axios.post(this.link+"/api/isDisplay/"+id,article)
        .then(response => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Updated',
            customClass:'sweetalertsuccess',
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => this.show());
    },

    deleteTeacher(id){
      let result = confirm("Want to delete?");
      if (result) {
        axios.delete(this.link+"/api/teacher-delete/" + id)
          .then(response => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Deleted',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => this.show());
      }
    },

    enableSortMode() {
      this.show();
      this.sortMode = true;
    },

    handleSortListChange(event) {
      this.sortedList = event.list;
    },

    saveSorting() {
      if (this.sortedList.length == 0 || this.sortedList.length != this.teacherList.length) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please arrange the teacher list first',
          confirmButtonText: 'OK',
        });

        return;
      }

      this.isSortingSaveBtnLoading = true;
      let payload = {
        teachers: this.sortedList
      }

      getAxiosClient()
        .post("teacher/update-sorting", payload)
        .then(response => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'List Updated',
            customClass:'sweetalertsuccess',
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(error => {
          console.log("Error sorting teacher list: ", error);
        })
        .finally(() => {
          this.sortMode = false;
          this.isSortingSaveBtnLoading = false;
          this.show();
        });
    },
  },

  mounted() {
    this.isLoading = true;
    try {
      this.show();
    } catch (error) {
      console.log("error mounting teacher page: ", error);
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped></style>
