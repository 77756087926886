<template>
  <div style="background-color:rgb(244, 234, 223)" class="p-3">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-sm-10 border rounded">
          <div class="d-flex justify-content-start mt-3">
            <button id="backButton" class="btn btn-primary btn-lg shadow" @click="goBack">
              <i class="bi bi-arrow-left-circle"></i> Go Back
            </button>
          </div>
          <div
            class="row  mt-3 d-flex justify-content-center   "
            v-for="(ques, index) in question"
            :key="index"
          >
            <div class="col-sm-8 mt-3   " style="text-align:left;">
              <h4 style="font-size:20px;" v-if="index != 'id'">{{ ques }}</h4>
              <p
                class="mt-3 form-control"
                v-if="index != 'id' && index != 'ans0'"
              >
                {{ displayedData[index] }}
              </p>
              <p class="mt-3 form-control" v-if="index == 'ans0'">
                {{ ['Not set!', '-', ''].includes(this.displayedData['ans1']) ? 'မရှိ' : 'ရှိ' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      link: 'https://backend.smkedugroup.com',
      link1: 'https://backend.smkedugroup.com',
      question: {
        id: 'ID',
        formdate: 'Form ဖြည့်သည့်ရက်စွဲ',
        studentname: 'ကျောင်းသား/သူ၏အမည်',
        gender: 'ကျား/မ',
        dateofbirth: 'ကျောင်းသား/သူ၏ မွေးသက္ကရာဇ်',
        course: 'စုံစမ်းလိုသည့်အတန်း',
        ans0: 'ရွှေမော်ကွန်းကျောင်းတွင်တက်ရောက်နေသောမောင်နှမအရင်း',
        ans1: 'ရွှေမော်ကွန်းကျောင်းတွင်တက်ရောက်နေသောမောင်နှမအရင်းရှိလျှင် အမည် နှင့် အတန်း',
        ans2: 'ကျောင်းသား/သူ၏ လူမျိုး/ဘာသာ',
        ans3: 'ဖခင်အမည်နှင့်အလုပ်အကိုင်',
        ans4: 'မိခင်အမည်နှင့်အလုပ်အကိုင်',
        ans5: 'အုပ်ထိန်းသူ၏အမည်နှင့်တော်စပ်ပုံ',
        ans6: 'မိဘ/အုပ်ထိန်းသူ၏ဖုန်းနံပါတ်',
        ans7: 'မိဘ/အုပ်ထိန်းသူ၏နေရပ်လိပ်စာ',
        ans8: '၁။ ရွှေမော်ကွန်း ကိုယ်ပိုင်အလယ်တန်းကျောင်းကို မည်ကဲ့သို့သိရှိပါသနည်း။ မည်သူ့အဆက်အသွယ် နှင့် သိရှိခဲ့ ပါသနည်း။',
        ans9: '၂။ မိဘ/အုပ်ထိန်းသူများ အနေနှင့် မိမိ၏သား/သမီးများအပေါ် ထားရှိသည့် မျှော်မှန်းချက် နှင့် ကျောင်းအပေါ် ထားရှိသည့် ရည်ရွယ်ချက်။',
        ans10: '၃။ မိဘ/အုပ်ထိန်းသူများ အနေနှင့် မိမိ၏ သား/ သမီး များအပေါ် ပညာရေးနှင့်ပတ်သက်၍ မည်ကဲ့သို့ပံ့ပိုး ပေးနိုင်ပါသနည်း။    ',
        ans11: '၄။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် မိမိတို့၏ သား/သမီးများကို ပြည်သူ့နီတိနှင့်ပတ်သက်၍ : မည်ကဲ့သို့  သင်ကြား ပေးထားပါသနည်း။',
        ans12: '၅။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် မိမိ သား/ သမီးများ၏ ပညာရေးနှင့်ပတ်သက်၍ ကျောင်းသား/ : မိဘ/ ဆရာ သုံးဦး သုံးဖလှယ် ပူးပေါင်းမှု ပြုနိုင်ပါသလား။ မည်ကဲ့သို့ပြုလုပ်ပါမည်နည်း။',
        ans13: '၆။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် မိမိကလေး၏ ကျန်းမာရေးအခြေအနေကို ကျောင်းရှိ ဆရာ/ ဆရာမ : များနှင့် ပွင့်လင်းစွာ ဆွေးနွေးတင်ပြမှု ပြုလုပ်နိုင်ပါသလား။',
        ans14: '၇။ ကျောင်းဘက်မှသိထားသင့်သော မိမိကလေး၏ ကျန်းမာရေးနှင့်ပတ်သက်၍ အောက်ဖော်ပြပါအချက်များနှင့် ကိုက်ညီမှုရှိခဲ့ပါက ထိုအချက်များကို အမှန်ခြစ်ပေးပါရန်။',
        ans15: '၈။ ကလေးများ၏ကျန်းမာရေးနှင့်ပတ်သက်၍ အခြားသိထားသင့်သောအချက်များရှိပါက ဖြည့်စွက်ပေးရန်။',
        ans16: '၉။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် Social Media (Facebook) ကို မည်ကဲ့သို့အသုံးပြုပါသနည်း။',
        ans17: '၁၀။ မိဘ/အုပ်ထိန်းသူများအနေနှင့် ရွှေမော်ကွန်းကိုယ်ပိုင်အလယ်တန်းကျောင်း၏ စည်းကမ်းပိုင်းနှင့်  ပတ်သက်၍ မည်မျှအထိ သိရှိနားလည်ထားပါသနည်း။',
        ans18: ' ၁၁။  ရွှေမော်ကွန်း ကိုယ်ပိုင် အလယ်တန်းကျောင်း မှ ချမှတ်ထားသော စည်းကမ်းချက်များအား မိဘ/အုပ်ထိန်းသူများ ဘက်မှ ပူးပေါင်းလိုက်နာ ဆောင်ရွက်နိုင်ပါ သလား။',
        subname: 'Form ဖြည့်သူ၏အမည် ၊ တော်စပ်ပုံနှင့် ဖုန်းနံပါတ်',
        subemail: 'Form ဖြည့်သူ၏ Email'
      },
      waitlingList: null,
      wid: '',
      displayedData: [],
    }
  },
  methods: {
    show() {
      axios.get(this.link + "/api/waitinglist")
        .then(response => {
          this.waitlingList = response.data;
          this.displayedData = this.waitlingList.find(data => data.id == this.wid);

        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
    goBack() {
      window.location.assign("/admin/waiting-list-view");
    },
  },
  mounted() {
    this.show();
    var query = this.$route.query.wid;
    if (!query) {
      window.location.assign("waiting-list-view")
    }
    else {
      this.wid = query;
    }
  },
}
</script>

<style lang="scss" scoped></style>
