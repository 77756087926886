<template>
  <template v-if="isLoading">
    <Loading />
  </template>
  <div v-else class="p-3">
    <FullCalendar :options="calendarOptions">
      <template v-slot:eventContent="arg">
        <div class="event">
          <font-awesome-icon
            :icon="clockIcons[arg.event.extendedProps.status]"
          />
          :
          {{ arg.event.extendedProps.booking_slot?.start_time || 'Not Set' }}
        </div>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import axios from 'axios';
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Loading from '@/components/general/Loading.vue';
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import interactionPlugin from "@fullcalendar/interaction";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faClock, faUserClock, faBriefcaseClock } from '@fortawesome/free-solid-svg-icons';

export default {
  props: {
    isTriggerReload: {
      default: null,
    },
  },

  components: {
    Loading,
    FullCalendar,
    FontAwesomeIcon,
  },

  data () {
    return {

      link: "https://backend.smkedugroup.com",
      isLoading: true,
      daySlotList: null,
      calendarOptions: {
        headerToolbar: {
          left: "dayGridMonth timeGridDay",
          center: "title",
          right: "prev today next",
        },
        buttonText: {
          today: "Today",
          month: "Month",
          week: "Week",
          day: "Day",
          list: "List",
        },
        eventBorderColor: "rgba(145, 85, 253, 0.16)",
        eventTextColor: "#696cff",
        eventBackgroundColor: "rgba(145, 85, 253, 0.16)",
        eventDisplay: "block",
        nowIndicator: "true",
        dayMaxEventRows: true, // for all non-TimeGrid views
        views: {
          dayGrid: {
            dayMaxEventRows: 3, // adjust to 6 only for timeGridWeek/timeGridDay
          },
        },
        plugins: [
          dayGridPlugin,
          interactionPlugin,
          bootstrap5Plugin,
          timeGridPlugin,
          listPlugin,
        ],
        themeSystem: "bootstrap5",
        initialView: "dayGridMonth",
        height: 570,
        events: [],
        eventClick: this.handleEventClick,
      },
      clockIcons: {
        0: faClock,
        1: faUserClock,
        2: faBriefcaseClock,
      },
    };
  },

  methods: {
    show() {
      axios.get(this.link + "/api/day-slots")
        .then(response => {
          this.daySlotList = response.data;
          this.calendarOptions.events = [
            ...this.daySlotList.filter(slot => slot.status == 1).map(item => {
              if (item.booking_slot) {
                item["title"] = item.booking_slot?.name;
                item["allDay"] = false;
                item["start"] = item.date + 'T' + item.booking_slot?.start_time + ':00';
                item["end"] = item.date + 'T' + item.booking_slot?.end_time + ':00';
              }
              return item
            }),
          ];
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);});
    },

    handleEventClick(el) {
      this.$emit('eventClick', {
        appointment_id: el.event._def.extendedProps.appointment_id
      });
    },
  },

  watch: {
    isTriggerReload(newId, oldId) {
      this.show();
    }
  },

  async mounted() {
    this.isLoading = true;

    try {
      this.show();
    } catch (error) {
      console.error("Error loading calendar slot");
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.event:hover {
  cursor: pointer;
}
</style>
