<template>
  <div class="p-3">
    <div
      class="modal fade"
      id="viewAppointmentModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              Appointment Details
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row d-flex justify-content-center ">
              <div class="col-sm-10 ms-3">
                <div class="mt-5 ">
                  <form class="">
                    <div class="row">
                      <div class="col ">
                        <div class="mb-3 row">
                          From {{ currentForm?.booking_slot?.start_time }} - To
                          {{ currentForm?.booking_slot?.end_time }}
                        </div>
                      </div>
                    </div>
                    <div class="row ">
                      <div class="col ">
                        <div class="mb-3 row">
                          <label
                            for="parent_name"
                            class="col-sm-2 col-form-label"
                            >Parent Name</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="text"
                              class="form-control"
                              :value="currentForm.parent_name"
                              id="parent_name"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="student_name"
                            class="col-sm-2 col-form-label "
                            >Student Name</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="text"
                              class="form-control"
                              :value="currentForm.student_name"
                              id="student_name"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="mb-3 row">
                          <label for="email" class="col-sm-2 col-form-label"
                            >Email</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="text"
                              class="form-control"
                              :value="currentForm.email"
                              id="email"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="inputPassword"
                            class="col-sm-2 col-form-label"
                            >Phone</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="text"
                              class="form-control"
                              :value="currentForm.phone"
                              id="phone"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="mb-3 row">
                          <label for="email" class="col-sm-2 col-form-label"
                            >Booking Date</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="date"
                              class="form-control"
                              :value="currentForm.booking_date"
                              id="booking_date"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3 row">
                          <label
                            for="formStatus"
                            class="col-sm-2 col-form-label"
                            >Status</label
                          >
                          <div class="col-sm-8 ms-3">
                            <input
                              type="text"
                              class="form-control"
                              :value="statusOptions[currentForm.status]"
                              id="formStatus"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="mb-3 text-start">
                        <label for="courses" class="form-label"
                          >Selected Courses</label
                        >
                        <ul id="courses">
                          <li
                            class="form-label"
                            v-for="course in currentForm.courses"
                            :key="course.id"
                          >
                            <label
                              class="form-check-label d-flex justify-content-start"
                              :for="'course_' + course.id"
                            >
                              {{ course.intake }} <br />
                              (Intake -
                              {{ course.fromMonth + ' - ' + course.toMonth}})
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-destory"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <template v-if="currentForm.status == 0">
              <button
                @click="confirmRecord(currentForm.id)"
                class="d-flex justify-align-center btn btn-warning"
                :disabled="isConfirmBtnLoading || isCancelBtnLoading"
              >
                <span
                  id="modalconfirmbtnspinner"
                  class="spinner-border"
                  role="status"
                  aria-hidden="true"
                  v-if="isConfirmBtnLoading"
                ></span>
                <span id="modalconfirmbtntext" v-if="! isConfirmBtnLoading">
                  Confirm
                </span>
              </button>

              <button
                v-on:click="cancelRecord(currentForm.id)"
                class="d-flex justify-align-center btn btn-danger"
                :disabled="isConfirmBtnLoading || isCancelBtnLoading"
              >
                <span
                  id="modalcancelbtnspinner"
                  class="spinner-border"
                  role="status"
                  aria-hidden="true"
                  v-if="isCancelBtnLoading"
                ></span>
                <span id="modalcancelbtntext" v-if="! isCancelBtnLoading">
                  Cancel
                </span>
              </button>
            </template>
            <button
              v-else
              @click="deleteRecord(currentForm.id)"
              class="d-flex justify-align-center btn btn-danger"
              :disabled="isDeleteBtnLoading"
            >
              <span
                id="modaldeletespinner"
                class="spinner-border"
                role="status"
                aria-hidden="true"
                v-if="isDeleteBtnLoading"
              ></span>
              <span id="modaldeletetext" v-if="! isDeleteBtnLoading">
                Delete Record
              </span>
            </button>

            <button
              id="viewModalClose"
              style="visibility: hidden;"
              type="button"
              data-bs-dismiss="modal"
            ></button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <h1 class="fw-bold">Appointment List</h1>

      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 ms-1">
              <div class="d-flex justify-content-between">
                <div
                  class="d-flex align-items-end justify-content-start gap-3 mb-3"
                >
                  <select
                    class="form-select"
                    aria-label="Default select"
                    v-model="filter.status"
                  >
                    <option value="">Select Status</option>
                    <option
                      :value="index"
                      :key="index"
                      v-for="(status, index) in statusOptions"
                    >
                      {{ status }}
                    </option>
                  </select>
                  <div class="d-flex gap-3">
                    <button class="btn btn-primary" @click="filterRecords">
                      Filter
                    </button>
                  </div>
                </div>

                <div class="d-flex justify-content-end mt-5 ">
                  <div class="mb-3 row">
                    <label for="search-key" class="col-sm-4 col-form-label"
                      >Search by</label
                    >
                    <div class="col-sm-6 ms-1">
                      <input
                        type="text"
                        class="form-control"
                        :value="search"
                        @input="searchData"
                        id="search-key"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-center mt-5 ">
                <div class="ms-1">
                  <div
                    class="container"
                    style="max-width:100%; max-height:1000px; overflow:scroll;"
                  >
                    <table class="table table-bordered table-hover">
                      <thead
                        style="position: sticky;top: 0;background-color: white;"
                      >
                        <tr>
                          <th>No</th>
                          <th>Parent Name</th>
                          <th>Student Name</th>
                          <th>Booking Date</th>
                          <th>Booking Time</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-if="isTableLoading">
                          <tr>
                            <td colspan="6">
                              <Loading />
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <template v-if="appointmentList.length > 0">
                            <tr
                              v-for="(data, index) in appointmentList"
                              :key="index"
                              @click="viewAppointment(data)"
                            >
                              <td
                                data-bs-toggle="modal"
                                data-bs-target="#viewAppointmentModal"
                              >
                                {{ index + 1 }}
                              </td>
                              <td
                                data-bs-toggle="modal"
                                data-bs-target="#viewAppointmentModal"
                              >
                                {{ data.parent_name }}
                              </td>
                              <td
                                data-bs-toggle="modal"
                                data-bs-target="#viewAppointmentModal"
                              >
                                {{ data.student_name }}
                              </td>
                              <td
                                data-bs-toggle="modal"
                                data-bs-target="#viewAppointmentModal"
                              >
                                {{ data.booking_date }}
                              </td>
                              <td
                                data-bs-toggle="modal"
                                data-bs-target="#viewAppointmentModal"
                              >
                                {{ data.booking_slot?.start_time }} -
                                {{ data.booking_slot?.end_time }}
                              </td>

                              <td v-if="data.status == 0">
                                <button
                                  v-on:click="confirmRecord(data.id)"
                                  class="btn btn-warning me-3 mb-3"
                                  :disabled="isConfirmBtnLoading || isCancelBtnLoading"
                                >
                                  <span
                                    id="confirmbtnspinner"
                                    class="spinner-border mb-3"
                                    role="status"
                                    aria-hidden="true"
                                    v-if="isConfirmBtnLoading"
                                  ></span>
                                  <span
                                    id="confirmbtntext"
                                    v-if="! isConfirmBtnLoading"
                                  >
                                    Confirm
                                  </span>
                                </button>

                                <button
                                  v-on:click="cancelRecord(data.id)"
                                  class="btn btn-danger"
                                  :disabled="isConfirmBtnLoading || isCancelBtnLoading"
                                >
                                  <span
                                    id="cancelbtnspinner"
                                    class="spinner-border"
                                    role="status"
                                    aria-hidden="true"
                                    v-if="isCancelBtnLoading"
                                  ></span>
                                  <span
                                    id="cancelbtntext"
                                    v-if="! isCancelBtnLoading"
                                  >
                                    Cancel
                                  </span>
                                </button>
                              </td>
                              <td
                                v-else
                                data-bs-toggle="modal"
                                data-bs-target="#viewAppointmentModal"
                              >
                                <span
                                  v-if="data.status == 1"
                                  class="text-warning"
                                >
                                  Confirmed!
                                </span>
                                <span
                                  v-if="data.status == 2"
                                  class="text-danger"
                                  >Canceled</span
                                >
                              </td>
                            </tr>
                          </template>
                          <tr v-else>
                            <td colspan="6">No appointment exist!</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>

                    <button
                      type="hidden"
                      id="triggeredViewModalBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#viewAppointmentModal"
                      style="display: none;"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import Loading from '@/components/general/Loading.vue';

export default {
  props: {
    selectedAppointmentId: {
      default: null,
    }
  },

  components: {
    Loading
  },

  data(){
    return{

      link: "https://backend.smkedugroup.com",
      isTableLoading: true,
      isConfirmBtnLoading: false,
      isCancelBtnLoading: false,
      isDeleteBtnLoading: false,
      appointmentList: [],
      search: '',
      statusOptions: {
        0: 'Pending',
        1: 'Confirmed',
        2: 'Canceled'
      },
      currentForm: {
        id: '',
        parent_name: '',
        student_name: '',
        email: '',
        phone: '',
        courses: [],
        booking_date: '',
        booking_time: '',
      },
      filter: {
        status: ''
      },
      appointmentId: this.selectedAppointmentId,
      isTriggerReload: true
    }
  },

  methods: {
    searchData(event) {
      this.search = event.target.value;
      this.filterRecords();
    },

    filterRecords() {
      if(this.search == "" && this.filter.status == "") {
        this.show();
        return;
      }

      this.isTableLoading = true;
      let payload = {};
      if (this.search != "") payload.search = this.search.toLowerCase();
      if (this.filter.status != "") payload.status = this.filter.status;

      axios.get(this.link+"/api/appointments", {
        params: {...payload}
      })
        .then(response => {
          this.appointmentList = response.data;
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => this.isTableLoading = false);
    },

    show() {
      this.isTableLoading = true;

      axios.get(this.link + "/api/appointments")
        .then(response => {
          this.appointmentList = response.data;
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => this.isTableLoading = false);
    },

    viewAppointment(data) {
      this.currentForm = data;
    },

    confirmRecord(id) {
      this.isConfirmBtnLoading = true;
      axios.post(this.link + "/api/appointments-confirmed/" + id)
        .then(response => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            customClass:'sweetalertsuccess',
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          this.isConfirmBtnLoading = false;
          this.closeModal();
          this.show();
          this.triggerReload();
        });
    },

    cancelRecord(id) {
      this.isCancelBtnLoading = true;
      axios.post(this.link + "/api/appointments-canceled/" + id)
        .then(response => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success',
            customClass:'sweetalertsuccess',
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          this.isCancelBtnLoading = false;
          this.closeModal();
          this.show();
          this.triggerReload();
        });
    },

    deleteRecord(id) {
      let result = confirm("Want to delete?");
      if (result) {
        this.isDeleteBtnLoading = true;
        axios
          .delete(this.link + "/api/appointments/" + id)
          .then((response) => {
            this.show();

            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Deleted',
              customClass:'sweetalertsuccess',
              showConfirmButton: false,
              timer: 1500
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => {
            this.isDeleteBtnLoading = false;
            this.closeModal();
            this.show();
            this.triggerReload();
          });
      }
    },

    closeModal() {
      $("#viewModalClose").click();
    },

    triggerReload() {
      this.$emit('tableReload', {
        isTriggerReload: this.isTriggerReload
      });
    }
  },

  watch: {
    selectedAppointmentId(newId, oldId) {
      if (newId) {
        this.currentForm = this.appointmentList.find(appointment => appointment.id == newId);

        if (this.currentForm) {
          $('#triggeredViewModalBtn').click();
        }
      }
    }
  },

  async mounted() {
    this.isTableLoading = true;

    try {
      this.show();
    } catch (error) {
      console.log("error mounting appointment page: ", error);
    } finally {
      this.isTableLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped></style>
